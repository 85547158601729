<template>
  <section class="scroll-container" ref="scrollContainer">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'appHorizontalScroll',
  mounted() {
    //TODO: добавить захват прокрутки экрана
    const scrollContainer = this.$refs.scrollContainer;

    scrollContainer.addEventListener('wheel', (event) => {
      scrollContainer.scrollLeft += event.deltaY;
    });
  }
}
</script>

<style scoped>
.scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
