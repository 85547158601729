<template>
  <init-modal
    class="approve-modal"
    @close="closeModal"
    :is-show="isShow"
    :is-bottom-position="screenWidth <= 414"
  >
    <div class="approve-modal__text">
      <slot></slot>
    </div>
    <div class="approve-modal__btns">
      <app-button @click="onApprove">Да</app-button>
      <app-button @click="closeModal">Нет</app-button>
    </div>
  </init-modal>
</template>

<script>
import initModal from '../init-modal/initModal.vue'
import appButton from '../../app-button/appButton.vue'
import { mapState } from 'vuex'

export default {
  name: 'approveModal',
  components: { initModal, appButton },
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['close', 'approve'],
  methods: {
    closeModal() {
      this.$emit('close')
    },
    onApprove() {
      this.$emit('approve')
    },
  },
  computed: {
    ...mapState({
      screenWidth: state => state.screenWidth,
    })
  }
}
</script>
