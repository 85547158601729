import Vue from 'vue'
import { TOAST } from '../constants/index'

const showToast = (message, type) => {
    Vue.toasted.show(
        message,
        {
            duration: TOAST.DURATION,
            containerClass: 'toasted-outer',
            className: 'toasted-inner',
            type,
            action: {
                text: 'Закрыть',
                onClick: (e, toastObject) => {
                    toastObject.goAway(0)
                },
            },
        },
    )
}

export default showToast
