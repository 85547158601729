var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VueDatePicker", {
    staticClass: "app-datepicker",
    attrs: {
      color: _vm.mainColor,
      "no-header": "",
      placeholder: "Выберите дату",
      "min-date": _vm.minDate,
      "max-date": _vm.maxDate,
      "fullscreen-mobile": "",
      name: _vm.label,
      "mobile-breakpoint": "425"
    },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function(ref) {
          var date = ref.date
          return [
            _c("app-input", {
              ref: "activator",
              attrs: {
                label: _vm.label,
                type: "button",
                value: _vm.replaceDate,
                "is-required": _vm.isRequired
              }
            })
          ]
        }
      }
    ]),
    model: {
      value: _vm.date,
      callback: function($$v) {
        _vm.date = $$v
      },
      expression: "date"
    }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f3ec0e7c", { render: render, staticRenderFns: staticRenderFns })
  }
}