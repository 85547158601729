var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "swiper",
        {
          ref: "mySwiper",
          staticClass: "swiper",
          attrs: { options: _vm.swiperOptions }
        },
        [
          _vm._l(_vm.slides, function(slide) {
            return _c(
              "swiper-slide",
              { key: slide.id, staticClass: "swiper-slide" },
              [
                _c("div", { staticClass: "swiper-slide__block" }, [
                  _c(
                    "div",
                    {
                      staticClass: "swiper-slide__description",
                      class: {
                        "swiper-slide__description-align-right":
                          slide.style.descriptionAlignRight,
                        "swiper-slide__description-align-bottom":
                          slide.style.descriptionAlignBottom
                      }
                    },
                    [
                      _c("h3", { style: "color:" + slide.titleColor }, [
                        _vm._v(_vm._s(slide.title))
                      ]),
                      _vm._v(" "),
                      _c("p", { style: "color:" + slide.subtitleColor }, [
                        _vm._v(_vm._s(slide.subtitle))
                      ]),
                      _vm._v(" "),
                      slide.buttonTitle
                        ? _c(
                            "a",
                            {
                              staticClass: "swiper-slide__button",
                              attrs: { href: _vm.getPath(slide) }
                            },
                            [_vm._v(_vm._s(slide.buttonTitle))]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "swiper-slide__img" }, [
                    _c("img", { attrs: { src: slide.croppedImage } })
                  ])
                ])
              ]
            )
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-pagination",
            attrs: { slot: "pagination" },
            slot: "pagination"
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-button-prev swiper-arrow",
            attrs: { slot: "button-prev" },
            slot: "button-prev"
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-button-next swiper-arrow",
            attrs: { slot: "button-next" },
            slot: "button-next"
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1a6afa8b", { render: render, staticRenderFns: staticRenderFns })
  }
}