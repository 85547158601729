// функция для определения окончания исчисляемых объектов
// value - текущее значение, words - массив из 3х слов
// words[0] = каким будет слово для одной единицы?
// words[1] = каким будет слово для двух единиц?
// words[2] = каким будет слово для пяти единиц?
// пример: currentWordEndings(667, ['товар', 'товара', 'товаров'])
export const currentWordEndings = (value, words) => {
    const currentValue = Math.abs(+value) % 100
    const num = currentValue % 10
    if (currentValue > 10 && currentValue < 20) return words[2]
    if (num > 1 && num < 5) return words[1]
    if (num === 1) return words[0]
    return words[2]
}
