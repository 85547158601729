import axios from 'axios'

const BASE_PATH = `${window.origin}/api/v0`

export const get = suffix => (
    axios
        .get(`${BASE_PATH}/${suffix}`)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response?.data))
)
export const post = (suffix, data) => (
    axios
        .post(`${BASE_PATH}/${suffix}`, data)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response?.data))
)
export const getById = (suffix, id) => get(`${suffix}/${id}`)
