/* eslint space-before-function-paren: ["error", "always"] */
/* eslint no-param-reassign: ["error", { "props": false }] */
import Vue from 'vue'
import Toasted from 'vue-toasted'

import * as mutations from './types/mutations-types'
import * as actions from './types/actions-types'

import {
    getDefaultCity,
    fetchOptionsFilters,
    fetchPriceRange,
    fetchQuestions,
    fetchCities,
    fetchVariants,
    fetchParameters,
    calculateVariants,
    createOrder,
    getLastUpdate,
    fetchDeliveryIntervals,
    fetchDeliveryPrice,
    fetchShopContacts,
} from '../api/shop'

Vue.use(Toasted)

export default {
    [actions.LOAD_OPTIONS_FILTERS] ({ commit }) {
        fetchOptionsFilters().then(
            options => commit(mutations.SET_OPTIONS_VARIANTS, options),
        )
    },

    [actions.LOAD_PRICE_RANGE] ({ commit }) {
        fetchPriceRange().then(
            range => commit(mutations.SET_PRICE_RANGE, range),
        )
    },

    [actions.LOAD_QUESTIONS] ({ commit }) {
        commit(mutations.CLEAR_CUSTOMER_ANSWERS)
        fetchQuestions().then(
            questions => commit(mutations.SET_QUESTIONS, questions),
        )
    },

    [actions.LOAD_CITIES] ({ state, commit }) {
        fetchCities()
            .then(cities => commit(mutations.SET_CITIES, cities.map(city => city.name)))
            .then(() => getDefaultCity())
            .then(defaultCity => {
                if (!state.customer.city) {
                    commit(mutations.UPDATE_CUSTOMER_CITY, {
                        city: defaultCity || state.cities[0],
                        exact: !!defaultCity,
                    })
                }
            })
    },

    [actions.LOAD_SHOP_CONTACTS] ({ commit }) {
        fetchShopContacts()
            .then(contacts => commit(mutations.SET_CONTACTS, contacts))
    },

    [actions.LOAD_DELIVERY_PRICE] ({ state, commit }) {
        if (state.customer.address.city !== '' && state.customer.address.street !== ''
            && state.customer.address.house !== '') {
            const fetchAddress = {
                city: state.customer.address.city,
                street: state.customer.address.street,
                house: state.customer.address.house,
            }
            commit(mutations.UPDATE_HTTP_IN_PROGRESS, true)
            fetchDeliveryPrice(fetchAddress)
                .then(
                    deliveryPrice => commit(mutations.SET_DELIVERY_PRICE, deliveryPrice[0]),
                )
                .finally(commit(mutations.UPDATE_HTTP_IN_PROGRESS, false))
        }
    },

    [actions.LOAD_DELIVERY_INTERVALS] ({ commit }) {
        fetchDeliveryIntervals()
            .then(deliveryIntervals => commit(mutations.SET_DELIVERY_INTERVALS, deliveryIntervals))
    },

    [actions.LOAD_VARIANTS] ({ commit }, productId) {
        commit(mutations.SET_CURRENT_VARIANTS, [])
        commit(mutations.SET_PARAMETERS_LENGTH, 0)
        commit(mutations.SET_PARAMETERS_VARIANTS, [])

        fetchVariants(productId)
            .then(variants => {
                commit(mutations.SET_CURRENT_VARIANTS, variants)
                return fetchParameters(productId)
            })
            .then(parameters => {
                commit(mutations.SET_PARAMETERS_VARIANTS, parameters)
                commit(mutations.SET_PARAMETERS_LENGTH, parameters.length)
            })
    },

    [actions.ADD_PRODUCT] ({ state, commit }) {
        Vue.toasted.show('Товар добавлен в корзину', { duration: 1000 })
        calculateVariants(state.draftProduct).then(
            variants => variants.forEach(variant => commit(mutations.ADD_PRODUCT, variant)),
        )
    },

    [actions.CREATE_ORDER] ({ state, commit }) {
        state.customer.delivery.unixTime = new Date(state.customer.delivery.date).getTime() / 1000
        const { customer, products } = state

        commit(mutations.UPDATE_HTTP_IN_PROGRESS, true)
        createOrder({ customer, products }).then(
            order => {
                commit(mutations.UPDATE_CUSTOMER_LAST_ORDER_ID, order.orderId)
                commit(mutations.CLEAR_PRODUCTS)
                commit(mutations.CLEAR_CUSTOMER_ANSWERS)
                commit(mutations.UPDATE_CUSTOMER_FILE, {})
                commit(mutations.UPDATE_CUSTOMER_MESSAGE, '')
                commit(mutations.UPDATE_CUSTOMER_ALLOW_PERSONAL_DATA_PROCESSING, false)
                commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
            },
            () => commit(mutations.UPDATE_HTTP_IN_PROGRESS, false),
        )
    },

    [actions.SEND_FEEDBACK] ({ state, commit }) {
        const { customer } = state

        Vue.toasted.show('Обращение принято. Мы свяжемся с вами.', { duration: 5000 })
        commit(mutations.UPDATE_HTTP_IN_PROGRESS, true)
        createOrder({ customer }).then(
            () => {
                commit(mutations.UPDATE_CUSTOMER_FILE, {})
                commit(mutations.UPDATE_CUSTOMER_MESSAGE, '')
                commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
            },
            () => commit(mutations.UPDATE_HTTP_IN_PROGRESS, false),
        )
    },

    [actions.CLEAR_PRODUCTS_ON_CATALOG_UPDATE] ({ commit }) {
        const STATE_CLEANER_KEY = 'qeep-state-cleaner'
        getLastUpdate().then(lastUpdated => {
            const needReload = localStorage.getItem(STATE_CLEANER_KEY) !== lastUpdated
            if (needReload) {
                commit(mutations.CLEAR_PRODUCTS_AND_DRAFT_PRODUCTS)
                localStorage.setItem(STATE_CLEANER_KEY, lastUpdated)
            }
        })
    },

    // eslint-disable-next-line object-curly-newline
    [actions.CHANGE_DRAFT_QUANTITY] ({ commit, id, delta, price }) {
        commit(
            mutations.CHANGE_DRAFT_PRODUCT_QUANTITY,
            {
                id,
                delta,
                price: parseInt(price, 10),
            },
        )
    },

    // eslint-disable-next-line object-curly-newline
    [actions.CHANGE_DISCRIMINATED_QUANTITY] ({ commit, id, delta, price, discriminator }) {
        commit(
            mutations.CHANGE_DRAFT_DISCRIMINATED_QUANTITY,
            {
                id,
                delta,
                price: parseInt(price, 10),
                discriminator,
            },
        )
    },
}
