export function formatAddress(data) {
    const {
        settlement_with_type, street, street_type, house_type, house, block_type, block,
    } = data

    let address = ''

    if (settlement_with_type) {
        address += settlement_with_type + ', '
    }
    if (street_type) {
        address += street_type + '. '
    }
    if (street) {
        address += street
    }
    if (house) {
        address += ', ' + house_type + '. ' + ' ' + house
    }
    if (block) {
        address += ' ' + block_type + '. ' + block
    }

    return address
}
