const mainWrap = document.querySelector('.wrapper')
const isExistVerticalScroll = () => document.body.offsetHeight > window.innerHeight
const screenPositionFromTop = () =>
    self.pageYOffset || (document.documentElement && document.documentElement.scrollTop)
const scrollWidth = () => window.innerWidth - document.documentElement.clientWidth

export default {
    blockBody() {
        mainWrap.dataset.scrollY = `${screenPositionFromTop()}`
        mainWrap.style.paddingRight = `${scrollWidth()}px`

        if (isExistVerticalScroll()) {
            mainWrap.classList.add('is-blocked')
            mainWrap.style.top = `-${mainWrap.dataset.scrollY}px`
        }
    },

    unblockBody() {
        mainWrap.classList.remove('is-blocked')
        mainWrap.style.paddingRight = '0px'

        if (isExistVerticalScroll()) {
            window.scrollTo(0, +mainWrap.dataset.scrollY)
        }
    },
}
