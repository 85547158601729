<template>
  <div :class="['app-textarea', dynamicWrapClasses]">
    <span :class="['app-textarea__label', { 'active': isActive }]">{{ label }}</span>
    <textarea
        :value="value"
        :disabled="isDisabled"
        :required="isRequired"
        @focus="isActive = true"
        @blur="onBlur"
        @input="$emit('input', $event.target.value)"
    ></textarea>
    <p v-if="isError" class="app-textarea__error">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'appTextarea',
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    isError() {
      return this.error.length > 0
    },
    dynamicWrapClasses() {
      return { 'error': this.isError }, { 'disabled': this.isDisabled }
    },
  },
  methods: {
    onBlur() {
      if (this.value === '') {
        this.isActive = false;
      }
    },
  },
  updated() {
    if (this.value?.length > 0) this.isActive = true
  },
}
</script>
