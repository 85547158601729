export const LOAD_OPTIONS_FILTERS = 'LOAD_OPTIONS_FILTERS'
export const LOAD_PRICE_RANGE = 'LOAD_PRICE_RANGE'
export const LOAD_QUESTIONS = 'LOAD_QUESTIONS'
export const LOAD_CITIES = 'LOAD_CITIES'
export const LOAD_SHOP_CONTACTS = 'LOAD_SHOP_CONTACTS'
export const LOAD_DELIVERY_PRICE = 'LOAD_DELIVERY_PRICE'
export const LOAD_DELIVERY_INTERVALS = 'LOAD_DELIVERY_INTERVALS'

export const LOAD_VARIANTS = 'LOAD_VARIANTS'

export const ADD_PRODUCT = 'ADD_PRODUCT'

export const CREATE_ORDER = 'CREATE_ORDER'
export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const CLEAR_PRODUCTS_ON_CATALOG_UPDATE = 'CLEAR_PRODUCTS_ON_CATALOG_UPDATE'
export const CHANGE_DRAFT_QUANTITY = 'CHANGE_DRAFT_QUANTITY'
export const CHANGE_DISCRIMINATED_QUANTITY = 'CHANGE_DISCRIMINATED_QUANTITY'
