export const validateInput = (value, options) => {
    const errors = {}

    if (options.isRequired && (value == null || value.trim() === '')) {
        errors.isRequired = 'Обязательное поле'
    }

    if (options.minLength && typeof value === 'string' && value.length < options.minLength) {
        errors.minLength = `Минимальная длина поля ${options.minLength} символов.`
    }

    if (options.maxLength && typeof value === 'string' && value.length > options.maxLength) {
        errors.maxLength = `Максимальная длина поля ${options.maxLength} символов.`
    }

    if (options.isPhoneFormat && typeof value === 'string' && value.length < 18) {
        errors.isPhoneFormat = 'Поле должно быть формата: +7 (xxx) xxx-xx-xx.'
    }

    return errors
}
