<template>
  <label class="app-checkbox">
    <input
      class="app-checkbox__input_real"
      v-model="isChecked"
      type="checkbox"
      :value="value"
      @change="emitClick"
    />
    <span class="app-checkbox__input_custom">
<!--  TODO: проблема прокинуть путь для картинки  -->
      <svg><polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg>
    </span>
    <slot>
      {{ label }}
    </slot>
  </label>
</template>

<script>
export default {
  name: 'AppCheckbox',
  props: {
    label: {
      type: String,
    },
    value: {
      type: Boolean,
    },
  },
  emits: ['change'],
  data() {
    return {
      isChecked: false,
    }
  },
  mounted() {
    this.isChecked = this.value
  },
  methods: {
    emitClick() {
      this.$emit('change', this.isChecked)
    },
  },
}
</script>
