export const INCREMENT_PRODUCT_QUANTITY = 'INCREMENT_PRODUCT_QUANTITY'
export const DECREMENT_PRODUCT_QUANTITY = 'DECREMENT_PRODUCT_QUANTITY'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_DRAFT = 'SET_DRAFT'
export const SET_AMOUNT = 'SET_AMOUNT'
export const SET_IMAGE_PATH = 'SET_IMAGE_PATH'
export const SET_MAIN_IMAGE = 'SET_MAIN_IMAGE'
export const SET_ACTIVE_IMAGE = 'SET_ACTIVE_IMAGE'
export const SET_ACTIVE_TOGGLE = 'SET_ACTIVE_TOGGLE'
export const SET_SCREEN_WIDTH = 'SET_SCREEN_WIDTH'
export const RESET_ACTIVE_TOGGLE = 'RESET_ACTIVE_TOGGLE'
export const RESET_ACTIVE_PRICE_VARIANT = 'RESET_ACTIVE_PRICE_VARIANT'
export const RESET_SELECTED_MODIFIERS = 'RESET_SELECTED_MODIFIERS'
export const RESET_ACTIVE_IMAGE = 'RESET_ACTIVE_IMAGE'
export const RESET_IMAGE_POSITION = 'RESET_IMAGE_POSITION'
export const ACTIVATE_PREV_IMAGE = 'ACTIVATE_PREV_IMAGE'
export const ACTIVATE_NEXT_IMAGE = 'ACTIVATE_NEXT_IMAGE'
export const CHANGE_TOGGLE_PARAMETER = 'CHANGE_TOGGLE_PARAMETER'

export const ADD_PRODUCT = 'ADD_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'

export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'

export const UPDATE_CROPPED_IMAGE = 'UPDATE_CROPPED_IMAGE'
export const UPDATE_CROPPED_IMAGE_ORIGIN = 'UPDATE_CROPPED_IMAGE_ORIGIN'
export const UPDATE_CROPPED_IMAGE_DATA = 'UPDATE_CROPPED_IMAGE_DATA'
export const SET_SLIDE_LINK_OPTIONS = 'SET_SLIDE_LINK_OPTIONS'

export const UPDATE_CUSTOMER_NAME = 'UPDATE_CUSTOMER_NAME'
export const UPDATE_CUSTOMER_EMAIL = 'UPDATE_CUSTOMER_EMAIL'
export const UPDATE_CUSTOMER_PHONE = 'UPDATE_CUSTOMER_PHONE'
export const UPDATE_CUSTOMER_MESSAGE = 'UPDATE_CUSTOMER_MESSAGE'
export const UPDATE_CUSTOMER_FILE = 'UPDATE_CUSTOMER_FILE'
export const UPDATE_CUSTOMER_ALLOW_SPAM = 'UPDATE_CUSTOMER_ALLOW_SPAM'
export const UPDATE_CUSTOMER_ALLOW_PERSONAL_DATA_PROCESSING = 'UPDATE_CUSTOMER_ALLOW_PERSONAL_DATA_PROCESSING'
export const UPDATE_CUSTOMER_ONLINE_PAYMENT = 'UPDATE_CUSTOMER_ONLINE_PAYMENT'
export const UPDATE_CUSTOMER_DELIVERY_TYPE = 'UPDATE_CUSTOMER_DELIVERY_TYPE'
export const UPDATE_CUSTOMER_DELIVERY_DATE = 'UPDATE_CUSTOMER_DELIVERY_DATE'
export const UPDATE_CUSTOMER_DELIVERY_INTERVAL = 'UPDATE_CUSTOMER_DELIVERY_INTERVAL'
export const UPDATE_CUSTOMER_ADDRESS_CITY = 'UPDATE_CUSTOMER_ADDRESS_CITY'
export const UPDATE_CUSTOMER_ADDRESS_STREET = 'UPDATE_CUSTOMER_ADDRESS_STREET'
export const UPDATE_CUSTOMER_ADDRESS_HOUSE = 'UPDATE_CUSTOMER_ADDRESS_HOUSE'
export const UPDATE_CUSTOMER_ADDRESS_APARTMENT = 'UPDATE_CUSTOMER_ADDRESS_APARTMENT'

export const UPDATE_CUSTOMER_CITY = 'UPDATE_CUSTOMER_CITY'

export const CLEAR_CUSTOMER_ANSWERS = 'CLEAR_CUSTOMER_ANSWERS'
export const UPDATE_CUSTOMER_ANSWER = 'UPDATE_CUSTOMER_ANSWER'

export const UPDATE_CUSTOMER_LAST_ORDER_ID = 'UPDATE_CUSTOMER_LAST_ORDER_ID'

export const UPDATE_HTTP_IN_PROGRESS = 'UPDATE_HTTP_IN_PROGRESS'

export const SET_SLIDES = 'SET_SLIDES'

export const SET_CROPPED_IMAGE = 'SET_CROPPED_IMAGE'
export const SET_CROPPED_IMAGE_ORIGIN = 'SET_CROPPED_IMAGE_ORIGIN'
export const SET_CROPPED_IMAGE_DATA = 'SET_CROPPED_IMAGE_DATA'

export const SET_SLIDE_PREVIEW = 'SET_SLIDE_PREVIEW'

export const SET_CURRENT_VARIANTS = 'SET_CURRENT_VARIANTS'
export const SET_PARAMETERS_LENGTH = 'SET_PARAMETERS_LENGTH'
export const SET_PARAMETERS_VARIANTS = 'SET_PARAMETERS_VARIANTS'
export const SET_OPTIONS_VARIANTS = 'SET_OPTIONS_VARIANTS'
export const SET_PRICE_RANGE = 'SET_PRICE_RANGE'
export const SET_QUESTIONS = 'SET_QUESTIONS'
export const SET_CITIES = 'SET_CITIES'
export const SET_CONTACTS = 'SET_CONTACTS'
export const SELECT_MODIFIER = 'SELECT_MODIFIER'
export const SELECT_GROUP_MODIFIER = 'SELECT_GROUP_MODIFIER'
export const SET_MODIFIERS = 'SET_MODIFIERS'
export const SET_VARIANTS = 'SET_VARIANTS'
export const SET_DELIVERY_INTERVALS = 'SET_DELIVERY_INTERVALS'
export const SET_DELIVERY_PRICE = 'SET_DELIVERY_PRICE'

export const UPDATE_FILTER_FROM_PRICE = 'UPDATE_FILTER_FROM_PRICE'
export const UPDATE_FILTER_TO_PRICE = 'UPDATE_FILTER_TO_PRICE'
export const UPDATE_FILTER_OPTIONS = 'UPDATE_FILTER_OPTIONS'

export const TOGGLE_FILTER_OPTION = 'TOGGLE_FILTER_OPTION'

export const RESET_PRODUCT_PARAMETERS = 'RESET_PRODUCT_PARAMETERS'
export const CHANGE_DRAFT_PRODUCT_PARAMETER = 'CHANGE_DRAFT_PRODUCT_PARAMETER'
export const CHANGE_DRAFT_DISCRIMINATED_PARAMETER = 'CHANGE_DRAFT_DISCRIMINATED_PARAMETER'

export const CHANGE_DRAFT_PRODUCT_QUANTITY = 'CHANGE_DRAFT_PRODUCT_QUANTITY'
export const CHANGE_DRAFT_DISCRIMINATED_QUANTITY = 'CHANGE_DRAFT_DISCRIMINATED_QUANTITY'

export const CLEAR_PRODUCTS_AND_DRAFT_PRODUCTS = 'CLEAR_PRODUCTS_AND_DRAFT_PRODUCTS'
