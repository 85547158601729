var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "init-modal",
    {
      staticClass: "product-modal",
      attrs: {
        "is-show": _vm.isShow,
        "is-bottom-position": _vm.screenWidth <= _vm.SCREENS.iPadMax
      },
      on: { close: _vm.closeModal }
    },
    [
      _c(
        "div",
        { staticClass: "product-modal__content" },
        [
          _c("app-product-slider", {
            attrs: {
              images: _vm.productImages,
              "need-reset-component": _vm.isShow
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product-modal__body" },
            [
              _c("div", { staticClass: "product-modal__body-product-desc" }, [
                _c("h2", { staticClass: "product-modal__body-title" }, [
                  _vm._v("\n          " + _vm._s(_vm.modalTitle) + "\n        ")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "product-modal__body-desc" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.productBody || _vm.productDescription) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.productVariantsListLength > 0 && _vm.multipleOptionsList > 1
                ? _c(
                    "div",
                    { staticClass: "tapes-list" },
                    _vm._l(_vm.productVariantsList, function(radioData, key) {
                      return _c("app-radio-tape", {
                        key: key,
                        attrs: { "radio-group": key, "radio-data": radioData },
                        on: {
                          change: function($event) {
                            return _vm.activateToggleProduct(
                              key,
                              $event,
                              _vm.productId
                            )
                          }
                        }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.productBuyable
                ? _c(
                    "div",
                    { staticClass: "product-modal__accordions" },
                    _vm._l(
                      _vm.productVariants[_vm.activePriceIndex].groupModifiers,
                      function(groupModifier, index) {
                        return _c("app-accordion", {
                          key: index,
                          attrs: { title: groupModifier.title },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function() {
                                  return [
                                    groupModifier.maxAmount === 1
                                      ? _vm._l(
                                          groupModifier.modifiers,
                                          function(radioModifier) {
                                            return _c("app-radio", {
                                              key: radioModifier.id,
                                              attrs: {
                                                "radio-data": {
                                                  groupName:
                                                    groupModifier.title,
                                                  value: radioModifier.id,
                                                  label:
                                                    radioModifier.productName,
                                                  isDefaultChecked: false
                                                }
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.selectRadioModifiersId(
                                                    $event,
                                                    groupModifier.title
                                                  )
                                                }
                                              }
                                            })
                                          }
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    groupModifier.maxAmount > 1
                                      ? _vm._l(
                                          groupModifier.modifiers,
                                          function(modifier) {
                                            return _c("app-checkbox", {
                                              key: modifier.id,
                                              attrs: {
                                                label:
                                                  modifier.productName +
                                                  " " +
                                                  modifier.price +
                                                  " ₽"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.selectCheckboxModifiersId(
                                                    modifier.id
                                                  )
                                                }
                                              }
                                            })
                                          }
                                        )
                                      : _vm._e()
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isUsingShopLogic
                ? [
                    _vm.productBuyable && _vm.amountProduct !== 0
                      ? _c(
                          "app-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addToBasket(_vm.productId)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          Добавить в корзину за " +
                                _vm._s(_vm.resultSum) +
                                " ₽\n        "
                            )
                          ]
                        )
                      : _c("app-button", { attrs: { "is-disabled": "" } }, [
                          _vm._v("\n          Нет в наличии\n        ")
                        ])
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e84176c2", { render: render, staticRenderFns: staticRenderFns })
  }
}