var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["app-input"].concat(_vm.dynamicWrapClasses) },
    [
      _c("span", { class: ["app-input__label", { active: _vm.isActive }] }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm._v(" "),
      _c(
        "input",
        _vm._b(
          {
            attrs: {
              type: _vm.type,
              inputmode: _vm.inputMode,
              min: _vm.min,
              max: _vm.max,
              disabled: _vm.isDisabled,
              required: _vm.isRequired
            },
            domProps: { value: _vm.inputValue },
            on: {
              input: _vm.handleChange,
              focus: _vm.handleFocus,
              blur: _vm.onBlur,
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.enterPressed.apply(null, arguments)
                },
                _vm.handleKeyDown
              ]
            }
          },
          "input",
          _vm._d({}, [_vm.dataAttr, true])
        )
      ),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.isError
        ? _c("p", { staticClass: "app-input__error" }, [
            _vm._v("\n    " + _vm._s(_vm.textError) + "\n  ")
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ff337434", { render: render, staticRenderFns: staticRenderFns })
  }
}