<template>
    <div>
        <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="slide of slides" :key="slide.id" class="swiper-slide">
                <div class="swiper-slide__block">
                    <div class="swiper-slide__description"
                        :class="{
                            'swiper-slide__description-align-right': slide.style.descriptionAlignRight,
                            'swiper-slide__description-align-bottom': slide.style.descriptionAlignBottom,
                        }">
                        <h3 :style="'color:' + slide.titleColor + '; white-space: pre'">{{ slide.title }}</h3>
                        <p :style="'color:' + slide.subtitleColor">{{ slide.subtitle }}</p>
                        <a class="swiper-slide__button" v-if="slide.buttonTitle" :href="getPath(slide)">{{ slide.buttonTitle }}</a>
                    </div>
                    <div class="swiper-slide__img">
                        <img :src="slide.croppedImage">
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import router from '../router'

import { mapState } from 'vuex'
import store from '../store'

export default {
    name: 'slider',
    store,
    data() {
        return {
            swiperOptions: {
                spaceBetween: 30,
                speed: 500,
                effect: 'fade',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                fadeEffect: {
                    crossFade: true,
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                loop: true,
            },
            alignRight: 0,
        }
    },
    components: {
        router,
        Swiper,
        SwiperSlide,
    },
    computed: {
        ...mapState({
            slides: (state) => {
                let slides = JSON.parse(state.slides)
                slides.forEach(slide => { slide.style = JSON.parse(slide.style) })

                return slides
            },
        }),
        swiper() {
            return this.$refs.mySwiper.$swiper
        },
    },
    methods: {
        getPath(slide){
            switch(slide.discr){
                case 'product':
                    return Routing.generate('product', { 'slug': slide.entity.slug })
                case 'brand':
                    return Routing.generate('brand', { 'slug': slide.entity.slug })
                case 'article':
                    return Routing.generate('article', { 'typeSlug': slide.entity.type.slug, 'slug': slide.entity.slug })
                case 'articleType':
                    return Routing.generate('articles', { 'slug': slide.entity.slug })
                case 'tag':
                    return Routing.generate('category', { 'slug': slide.entity.slug })
                case 'custom':
                    return slide.slideLinkUrl
            }
        },
    },

    mounted() {
        this.swiper.slideTo(1, 1000, false)
    },
};
</script>

<style scoped>
@import '../../../assets/css/variables.css';

.swiper-slide__block {
    position: relative;
    width: 100%;
    height: 100%;
}

.swiper-slide__description-align-right {
    align-items: flex-end;
    left: 40%;
}

.swiper-slide__description-align-right p, .swiper-slide__description-align-right h3 {
    text-align: right;
}

.swiper-slide__description-align-bottom {
    bottom: 0;
}

.swiper-slide__description {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 60%;
    max-height: 100%;
    height: 100%;
    flex-direction: column;
    padding: 91px 130px;
    z-index: 10;
}

.swiper-slide__description h3 {
    flex-shrink: 0;
    margin: 0;
    max-width: 150%;
    text-align: left;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 97px;
    font-size: 80px;
    font-weight: 700;
}

.swiper-slide__description p {
    max-width: 100%;
    word-wrap: break-word;
    text-align: left;
    overflow: hidden;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
}

.swiper-slide__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    width: 197px;
    height: 45px;
    text-decoration: none;
    color: #F2F2F2;
    background: #EF4618;
}

.swiper-slide__button:hover {
    cursor: pointer;
    background-color: #F7967D;
}

.swiper-slide__button:active {
    background: #9B2708;
}

.swiper-slide__img {
    position: absolute;
    right: 0;
    height: 100%;
}

.swiper-slide__img img {
    height: 100%;
    border-radius: var(--border-radius-small);
    object-fit: cover;
}

@media (max-width: 768px) {
    .swiper-slide__img {
        padding-right: 45px;
    }
}

.swiper {
    height: 500px;
    width: 100%;
}

.swiper-slide {
    height: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
    overflow: hidden;
}

.swiper-pagination >>> .swiper-pagination-bullet-active {
    background-color: var(--main-color);
}

.swiper-arrow {
    color: var(--main-color);
    opacity:.3;
    transition: 0.3s;
}

.swiper-arrow:hover {
    color: color-mod(var(--main-color) lightness(+10%));
    opacity:1;
}

@media (max-width: 1680px) {
    .swiper-slide__description {
        padding: 91px 100px;
    }
}

@media (max-width: 1440px) {
    .swiper-slide {
        height: 333px;
    }

    .swiper-slide__description {
        padding: 91px 50px;
    }

    .swiper {
        height: 500px;
    }
}

@media (max-width: 1200px) {
    .swiper-slide {
        height: 274px;
    }

    .swiper {
        height: 400px;
        width: 100%;
    }
}

@media (max-width: 990px) {
    .swiper-slide {
        height: 210px;
    }

    .swiper {
        height: 300px;
        width: 100%;
    }

    .swiper-slide__description {
        padding: 30px 12px 21px 12px;
    }

    .swiper-slide__description h3 {
        font-size: 30px;
    }

    .swiper-slide__description p {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .swiper-slide {
        height: 155px;
    }

    .swiper-slide__description p {
        line-height: 1.5em;
    }
}

@media (max-width: 600px) {
    .swiper-slide {
        height: 148px;
    }

    .swiper-slide__description h3 {
        font-size: 24px;
        line-height: 1.2;
    }

    .swiper-slide__description p {
        font-size: 16px;
        line-height: 1.2;
        margin: 0;
    }

    .swiper {
        height: 230px;
    }

    .swiper-slide__button {
        padding: 0 20px;
        min-width: auto;
        width: auto;
        height: 33px;
        line-height: 17px;
        font-size: 14px;
    }
}

@media (max-width: 414px) {
    .swiper-slide {
        height: 100px;
    }

    .swiper-slide__description h3 {
        font-size: 18px;
    }

    .swiper {
        height: 200px;
    }

    .swiper-slide__description p {
        font-size: 10px;
    }
}
</style>
