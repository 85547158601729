var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "init-modal",
    {
      staticClass: "approve-modal",
      attrs: {
        "is-show": _vm.isShow,
        "is-bottom-position": _vm.screenWidth <= 414
      },
      on: { close: _vm.closeModal }
    },
    [
      _c("div", { staticClass: "approve-modal__text" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "approve-modal__btns" },
        [
          _c("app-button", { on: { click: _vm.onApprove } }, [_vm._v("Да")]),
          _vm._v(" "),
          _c("app-button", { on: { click: _vm.closeModal } }, [_vm._v("Нет")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-59e2503a", { render: render, staticRenderFns: staticRenderFns })
  }
}