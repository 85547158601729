<template>
  <app-button
    class="app-progress-button"
    :is-disabled="isDisabled || inProgress"
    @click="click"
  >
    <span v-if="inProgress" class="app-progress-button__inner">
      <app-loader></app-loader>
      {{ progressText }}
    </span>
    <span v-else>{{ buttonText }}</span>
  </app-button>
</template>

<script>
import appButton from '../app-button/appButton.vue'
import appLoader from '../app-loader/appLoader.vue'

export default {
  name: 'appProgressButton',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    progressText: {
      type: String,
      default: 'Ожидайте, выполняется запрос',
    }
  },
  emits: ['click'],
  components: {
    appButton,
    appLoader,
  },
  methods: {
    click() {
      if (!this.inProgress) {
        this.$emit('click')
      }
    },
  },
}
</script>
