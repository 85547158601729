var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "app-checkbox" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.isChecked,
            expression: "isChecked"
          }
        ],
        staticClass: "app-checkbox__input_real",
        attrs: { type: "checkbox" },
        domProps: {
          value: _vm.value,
          checked: Array.isArray(_vm.isChecked)
            ? _vm._i(_vm.isChecked, _vm.value) > -1
            : _vm.isChecked
        },
        on: {
          change: [
            function($event) {
              var $$a = _vm.isChecked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = _vm.value,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.isChecked = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.isChecked = $$c
              }
            },
            _vm.emitClick
          ]
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "app-checkbox__input_custom" }, [
        _c("svg", [_c("polyline", { attrs: { points: "1.5 6 4.5 9 10.5 1" } })])
      ]),
      _vm._v(" "),
      _vm._t("default", function() {
        return [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7ab88ed0", { render: render, staticRenderFns: staticRenderFns })
  }
}