<template>
  <div
      class="app-counter"
      v-cloak
  >
    <button
        class="app-counter__button app-counter__button_negative"
        @click.stop="decrement"
    ></button>
    <span class="app-counter__count">{{ count }}</span>
    <button
        class="app-counter__button app-counter__button_positive"
        @click.stop="increment"
    ></button>
  </div>
</template>

<script>
export default {
  name: 'appCounter',
  props: {
    count: {
      type: [Number, String],
      required: true,
    },
  },
  emits: ['decrement', 'increment'],
  methods: {
    decrement() {
      this.$emit('decrement')
    },
    increment() {
      this.$emit('increment')
    }
  }
}
</script>
