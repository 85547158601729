import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import * as getters from './getters'
import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

export const state = {
    screenWidth: 0,
    isModalOpen: false,
    isUsingShopLogic: true,
    selectedParametersIndexMap: {},
    activeImageIndex: 0,
    currentImageIndex: 0,
    pathImg: '',
    mainImg: '',
    slides: {},
    slideLinkOptions: {},
    cropped: {
        image: '/media/downloads/placeholder-image.png',
        imageOrigin: '/media/downloads/placeholder-image.png',
        imageData: '',
    },
    selectedModifiers: [],
    selectedGroupModifier: [],
    productPriceModifiers: 0,
    productVariantsList: [],
    slidePreview: {
        title: '',
        subtitle: '',
        buttonTitle: '',
        titleColor: 0,
        subtitleColor: 0,
        align: '',
    },
    product: {
        id: '',
        title: '',
        body: '',
        description: '',
        price: '',
        images: [],
        isBuyable: true,
        variants: [{
            parameters: {
                key: '',
                value: '',
            },
        }],
    },
    customer: {
        name: '',
        email: '',
        phone: '',
        message: '',
        lastOrderId: null,
        answers: [],
        city: '',
        address: {
            city: null,
            street: '',
            house: '',
            apartment: '',
        },
        allowSpam: true,
        allowPersonalDataProcessing: false,
        onlinePayment: true,
        delivery: {
            type: 'courier',
            date: '',
            unixTime: null,
            interval: '',
            hoursFrom: 0,
            hoursTo: 0,
            deliveryPrice: 0,
        },
    },
    cityExact: false,
    draftProduct: {},
    activePriceIndex: 0,
    amountProduct: null,
    currentVariants: [],
    availableParameters: [],
    lengthParameters: 0,
    cities: [],
    // двумерный массив дней от 0 до 13, в каждом из которых интервалы доставки
    // временным промежутками
    deliveryIntervals: [[{ date: '' }]],
    questions: [],
    filters: {
        price: {
            min: 0,
            max: 0,
        },
        options: [],
        active: {
            price: {
                from: 0,
                to: 0,
            },
            options: [],
        },
    },
    shopContacts: {
        address: '',
        email: '',
        phone: '',
    },
    products: [],
    httpInProgress: false,
}

if (process.env.NODE_ENV === 'development') {
    Vue.config.devtools = true
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    plugins: [createPersistedState({
        paths: [
            'customer',
            'cityExact',
            'draftProducts',
            'currentVariants',
            'availableParameters',
            'lengthParameters',
            'cities',
            'questions',
            'filters',
            'products',
            'httpInProgress',
        ],
    })],
})
