var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: ["sidebar", { open: _vm.isOpen }] }, [
    _c("div", {
      staticClass: "sidebar__overlay",
      on: { click: _vm.hideSidebar }
    }),
    _vm._v(" "),
    _c(
      "section",
      {
        ref: "sidebarBody",
        class: ["sidebar__inner", { right: _vm.isRightPosition }],
        on: {
          touchstart: function($event) {
            return _vm.startSwipe($event)
          },
          touchmove: function($event) {
            return _vm.swipe($event)
          },
          touchend: _vm.stopSwipe
        }
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-85d534b4", { render: render, staticRenderFns: staticRenderFns })
  }
}