export const locales = {
    name: 'свое имя',
    phone: 'свой телефон',
    message: 'комментарий',
}

// массивы для currentWordEndings()
export const productEndings = ['товар', 'товара', 'товаров']

export const bonusEndings = ['бонус', 'бонуса', 'бонусов']
