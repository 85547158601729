var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { staticClass: "app-loader" }, [
    _c("circle", {
      staticClass: "app-loader__circle",
      attrs: { cx: "15", cy: "15", r: "8" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-74e31cb0", { render: render, staticRenderFns: staticRenderFns })
  }
}