const onlyUnique = (value, index, self) => self.indexOf(value) === index

export const mapVariantsToParameters = variants => {
    const keys = []
    let parameters = []
    const keysandvalues = []
    const result = []
    variants.forEach(variant => {
        parameters = variant.parameters
        parameters.forEach(parameter => {
            const { key, value } = parameter
            keysandvalues.push({ key, value })
            keys.push(key)
        })
    })
    const uniqueKeys = keys.filter(onlyUnique)

    uniqueKeys.forEach(uniqueKey => {
        const values = []
        keysandvalues.forEach(keyandvalue => {
            if (keyandvalue.key === uniqueKey) {
                values.push(keyandvalue.value)
            }
        })
        const uniqueValues = values.filter(onlyUnique)
        result.push({ key: uniqueKey, values: uniqueValues })
    })

    return result
}

export const filterVariantsByParameters = (variants, parameters) => (
    variants
        .filter(variant => {
            let flag = true
            parameters.forEach(parameter => {
                flag = false
                variant.parameters.forEach(ownParameter => {
                    if (parameter.key === ownParameter.key
                        && parameter.value === ownParameter.value) {
                        flag = true
                    }
                })
                if (!flag) return flag
            })
            return flag
        })
)

/**
 * Функция formatVariant используется для форматирования варианта продукта (purchase).
 *
 * @param {Object} currentProduct - Текущий продукт.
 * @param {Object} currentVariant - Текущий вариант продукта.
 * @param {Array} modifierIds - Массив идентификаторов модификаторов.
 * @returns {Object} - Отформатированный вариант продукта.
 */
export const formatVariant = (currentProduct, currentVariant, modifierIds) => {
    let modifiersSum = 0
    const modifierNames = []

    return {
        ...currentVariant,
        quantity: 1,
        productId: generateProductId(currentVariant?.id, modifierIds),
        images: currentProduct?.images,
        modifiersIdList: modifierIds.sort((a, b) => a - b),
        groupModifiers: currentVariant?.groupModifiers.map(groupModifier => ({
            ...groupModifier,
            modifiers: groupModifier?.modifiers
                .filter(modifier => modifierIds.includes(modifier.id))
                .map(modifier => {
                    modifiersSum += +modifier?.price | 0
                    modifierNames.push(modifier?.productName)

                    return {
                        ...modifier,
                        quantity: modifier?.quantity || 1,
                    }
                }),
        })),
        variantTotalSum: (currentVariant?.price | 0) + modifiersSum,
        modifierNamesList: [...modifierNames],
    }
}

/**
 * Функция для поиска варианта продукта в корзине по идентификатору позиции в корзине.
 */
export const getVariantFromBasketById = (basket, basketItemId) => basket.find(v => v.productId === basketItemId)

/**
 * Функция для поиска варианта продукта без модификаторов по идентификатору продукта в корзине.
 */
export const findVariantWithoutModifiers = (basket, productId) => basket
    .find(v => v.productId === productId.toString() && v.modifiersIdList.length === 0)

/**
 * Генерирует уникальный идентификатор продукта на основе идентификатора варианта и списка идентификаторов модификаторов.
 * @param {string} variantId - Идентификатор текущего варианта продукта.
 * @param {Array.<string>} modifierIds - Список идентификаторов выбранных модификаторов.
 * @returns {string} - Уникальный идентификатор продукта, состоящий из идентификатора варианта и списка идентификаторов модификаторов, разделённых символом "#".
 */
export const generateProductId = (variantId, modifierIds) => {
    return variantId + (modifierIds.length > 0 ? `#${modifierIds.join('#')}` : '')
}
