<template>
  <div class="autocomplete">
    <app-input
        label="Указать адрес"
        v-model="inputValue"
        :is-required="isRequired"
        :show-validation-error="inputValidation"
        @validation-change="updateOrderFormValidation($event)"
        @input="handleInput"
        @keydown.down.prevent="handleKeyDown"
        @keydown.up.prevent="handleKeyUp"
        @keydown.enter.prevent="handleEnter"
        @focus="handleFocus"
        @blur="handleBlur"
    />
    <ul v-if="isVisibleList" class="autocomplete__prompt-list">
      <li
          class="autocomplete__prompt-item"
          v-for="(address, index) in searchedAddresses"
          :key="index"
          :class="{ 'active': index === selectedIndex }"
          @click="handleLoadedAddressClick(index, isOnCreateOrderPage)"
      >
        {{ address }}
      </li>
    </ul>
    <span class="autocomplete__prompt-message">{{ message }}</span>
  </div>
</template>

<script>
import appInput from '../app-input/appInput.vue'
import { mapState } from 'vuex'
import fullStreetTemp from '@/mixins/fullStreetTemp'
import { formatAddress } from '@utils/formatAddress'

export default {
  name: 'appAutocomplete',
  components: {
    appInput,
  },
  mixins: [fullStreetTemp],
  emits: [
    'click',
    'change-full-street',
    'loaded-address-click',
    'validation-change',
    'input'
  ],
  props: {
    isOnCreateOrderPage: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true
    },
    showValidationError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
      isListOpened: false,
      selectedIndex: -1,
      inputValidation: this.showValidationError || false,
      isInputFocused: false,
    }
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue
    },
    showValidationError(newValue) {
      this.inputValidation = newValue
    },
    searchedAddresses(newValue) {
      this.isListOpened = newValue.length > 0
    },
  },
  computed: {
    ...mapState({
      deliveryAddress: state => state.deliveryAddress,
      message: state => state.deliveryDetails.message
    }),
    isVisibleList() {
      return this.isListOpened && this.inputValue.trim() && this.isInputFocused
    },
    searchedAddresses() {
      if (!this.deliveryAddress || !this.deliveryAddress.loadedAddresses) {
        return []
      }

      const addresses = this.deliveryAddress.loadedAddresses.map(loadedAddress => {
        return formatAddress(loadedAddress.data)
      })

      return addresses.length > 0 ? addresses : ['Не найдено']
    }
  },
  methods: {
    handleLoadedAddressClick(index, isCreateOrderPage) {
      if (this.deliveryAddress.loadedAddresses && this.deliveryAddress.loadedAddresses[index]) {
        const loadedAddress = this.deliveryAddress.loadedAddresses[index].data
        this.inputValue = formatAddress(loadedAddress)
        this.isListOpened = false
        this.selectedIndex = index
        this.isInputFocused = false
        this.$emit('loaded-address-click', loadedAddress, isCreateOrderPage)
      }
    },
    handleInput(value) {
      this.isListOpened = true

      if (value.trim().length > 0) {
        this.isInputFocused = true
      }

      this.$emit('input', value)
    },
    handleKeyDown() {
      if (this.selectedIndex < this.deliveryAddress.loadedAddresses.length - 1) {
        this.selectedIndex++
      }
    },
    handleKeyUp() {
      if (this.selectedIndex > 0) {
        this.selectedIndex--
      }
    },
    handleEnter() {
      if (this.selectedIndex !== -1) {
        this.handleLoadedAddressClick(this.selectedIndex, this.isOnCreateOrderPage)
      }
    },
    updateOrderFormValidation(event) {
      this.$emit('validation-change', event)
    },
    handleFocus() {
      this.isInputFocused = true
    },
    handleBlur() {
      if (!this.inputValue.trim()) {
        this.isInputFocused = false
      }
    },
  },
}
</script>
