<template>
  <div class="app-card-button">
    <button
      @click="emitActiveValue"
      class="app-card-button__btn"
      :class="{ 'app-card-button__btn_active' : isActive }"
    >
      <div class="app-card-button__btn-header">
        <span class="app-card-button__btn-header-title no-line-break">{{ item.name }}</span>
        <div class="app-card-button__btn-control">
          <button class="app-card-button__btn-control-edit">
            <!-- TODO: сейчас проблема прокинуть путь до картинки -->
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.165 6.11801L13.8816 3.83468C13.5836 3.55476 13.1931 3.39415 12.7844
                3.38339C12.3757 3.37263 11.9773 3.51249 11.665 3.77635L4.16496 11.2763C3.8956
                11.548 3.72788 11.904 3.68996 12.2847L3.33163 15.7597C3.3204 15.8817 3.33624
                16.0048 3.37801 16.12C3.41978 16.2352 3.48646 16.3398 3.57329 16.4263C3.65116
                16.5036 3.74351 16.5647 3.84504 16.6062C3.94657 16.6476 4.05529 16.6686 4.16496
                16.668H4.23996L7.71496 16.3513C8.09562 16.3134 8.45166 16.1457 8.72329
                15.8763L16.2233 8.37635C16.5144 8.06882 16.6717 7.65844 16.6608 7.23513C16.6498
                6.81182 16.4715 6.4101 16.165 6.11801ZM7.56496 14.6847L5.06496 14.918L5.28996
                12.418L9.99829 7.76801L12.2483 10.018L7.56496 14.6847ZM13.3316 8.90135L11.0983
                6.66801L12.7233 5.00135L14.9983 7.27635L13.3316 8.90135Z" fill="#333333"
              />
            </svg>
          </button>
          <button class="app-card-button__btn-control-delete"></button>
        </div>
      </div>
      <!-- В объектах разные названия, поэтому нужно условие -->
      <span class="app-card-button__btn-description no-line-break">
        {{ item.fullStreet ? item.fullStreet : item.number }}
      </span>
    </button>
    <span
        class="app-card-button__btn-status"
        :class="{ 'app-card-button__btn-status_success' : isActive }"
    >
        {{ statusText }}
      </span>
  </div>
</template>

<script lang="js">
export default {
  name: 'appCardButton',
  emits: ['active', 'delete', 'edit'],
  props: {
    item: {
      type: Object,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    statusText: {
      type: String,
      default: '',
    }
  },
  methods: {
    emitActiveValue() {
      this.$emit('active', this.item)
    },
  },
}
</script>
