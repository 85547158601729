<template>
  <label class="app-radio">
    <input
        class="app-radio__input_real"
        :checked="isChecked"
        type="radio"
        :name="radioData.groupName"
        :value="radioData.value"
        @change="emitActiveValue($event)"
    />
    <span class="app-radio__input_custom" />
    {{ radioData.label }}
  </label>
</template>

<script lang="js">
export default {
  name: 'AppRadio',
  props: {
    radioData: {
      type: Object,
      required: true,
      default: () => ({
        groupName: '',
        value: '',
        label: '',
        isDefaultChecked: false,
      }),
    },
  },
  emits: ['change'],
  computed: {
    isChecked: {
      get() {
        return this.radioData.isDefaultChecked
      },
      set(value) {
        this.$emit('change', value)
      },
    },
  },
  methods: {
    emitActiveValue($event) {
      this.isChecked = $event.target.value
    },
  },
}
</script>
