<template>
  <!--TODO: вынести в ui-->
  <div class="app-accordion">
    <button class="app-accordion__btn"  :class="{ 'is-active': isOpen }" @click="isOpen = !isOpen">
      <span class="app-accordion__btn-text">{{ title }}</span>
      <!-- TODO: сейчас проблема прокинуть путь до картинки -->
      <svg
        viewBox="0 0 20 20"
        fill="#333333"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M17.9543 6.61598C17.7199 6.38164 17.402 6.25 17.0706 6.25C16.7391 6.25
                16.4212 6.38164 16.1868 6.61598L9.99933 12.8035L3.81183 6.61598C3.57608
                6.38829 3.26032 6.26229 2.93258 6.26514C2.60483 6.26799 2.29132 6.39945
                2.05956 6.63121C1.8278 6.86297 1.69633 7.17648 1.69349 7.50423C1.69064
                7.83198 1.81663 8.14773 2.04433 8.38348L9.11558 15.4547C9.34999 15.6891
                9.66787 15.8207 9.99933 15.8207C10.3308 15.8207 10.6487 15.6891 10.8831
                15.4547L17.9543 8.38348C18.1887 8.14907 18.3203 7.83119 18.3203 7.49973C18.3203
                7.16828 18.1887 6.85039 17.9543 6.61598Z"
            fill="currentColor"
        />
      </svg>
    </button>
    <div ref="accordionContent" class="app-accordion__content" :class="{ 'hidden' : !isOpen }">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script lang="js">
  export default {
    name: 'app-accordion',
    props: {
      title: {
        type: String
      },
    },
    data: () => ({
      isOpen: true,
    }),
    updated() {
      // Задаем максимальную высоту контента, чтобы аккордеон раскрывался полностью
      this.$refs.accordionContent.style.maxHeight = `${this.$refs.accordionContent.scrollHeight}px`
    },
  }
</script>

<style>
.app-accordion__btn-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

  .app-accordion__content {
    display: flex;
    flex-direction: column;
    grid-row-gap: 4px;
    margin: 8px 0 0 4px;
    overflow-y: auto;
  }
  /*TODO: подумать над аккордионом, чтобы избежать important*/
  .app-accordion__content.hidden {
    max-height: 0!important;
  }
</style>
