<template>
  <div class="app-auth-code">
    <div class="app-auth-code__list">
      <input
        :class="dynamicClass"
        v-for="(code, index) in codes"
        :key="index"
        type="text"
        inputmode="numeric"
        v-model="code.value"
        @input="handleInput(code, index, $event)"
        @keydown="handleKeyDown(index, $event)"
        @emit-code="emitCode"
        @tap-enter="tapEnter"
      />
    </div>
    <p class="app-auth-code__error" v-if="isError">{{ textError }}</p>
  </div>
</template>

<script>
import appInput from '../app-input/appInput.vue'

export default {
  name: 'appAuthCode',
  components: {
    appInput,
  },
  emits: [
    'emit-code',
    'tap-enter'
  ],
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    textError: {
      type: String,
      default: 'Неправильный код',
    },
    countOfInputs: {
      type: Number,
      default: 4,
    }
  },
  data() {
    return {
      codes: [],
      repeatBackspace: false,
    }
  },
  methods: {
    handleInput(code, index, event) {
      if (!this.isNumber(event.target.value)) {
        event.target.value = ''
        code.value = ''
      } else {
        // Ограничиваем ввод до одного символа
        if (event.target.value.length > 1) {
          event.target.value = event.target.value.slice(0, 1)
        }
        // Сохраняем текущее значение кода
        code.value = event.target.value
        // Переключаем фокус на следующий инпут, если текущий заполнен
        event.target.value.length > 0 && this.handleFocusNext(index, event)
        // Сбрасываем ввод на начало строки (необходимо при редактировании заполненных значений)
        event.target.setSelectionRange(0, 0)
        this.emitCode()
      }
    },
    handleKeyDown(index, event) {
      switch (event.key) {
        case 'Delete':
          if (!this.repeatBackspace) {
            this.handleDelete(index)
            this.repeatBackspace = true
          } else {
            this.handleFocusPrev(index, event)
            this.repeatBackspace = false
          }
          break
        case 'Backspace':
          if (!this.repeatBackspace) {
            this.handleDelete(index)
            this.repeatBackspace = true
          } else {
            this.handleFocusPrev(index, event)
            this.repeatBackspace = false
          }
          break
        case 'ArrowLeft':
          this.handleFocusPrev(index, event)
          this.repeatBackspace = false
          break
        case 'ArrowRight':
          this.handleFocusNext(index, event)
          this.repeatBackspace = false
          break
        case 'Enter':
          this.isValidCode() && this.tapEnter()
          this.repeatBackspace = false
        default:
          break
      }
    },
    isNumber(value) {
      return +value % 1 === 0
    },
    isValidCode() {
      return this.fullCode.length === this.codes.length && this.isNumber(this.fullCode)
    },
    handleDelete(index) {
      this.codes[index].value = ''
    },
    handleFocusNext(index, event) {
      const lastIndex = this.codes.length - 1
      index !== lastIndex && event.target.nextElementSibling.focus()
    },
    handleFocusPrev(index, event) {
      const firstIndex = 0
      index !== firstIndex && event.target.previousElementSibling.focus()
    },
    emitCode() {
      this.$emit('emit-code', this.fullCode)
    },
    tapEnter() {
      this.$emit('tap-enter')
    },
  },
  computed: {
    fullCode() {
      return this.codes.reduce((acc, curr) => acc + curr.value, '')
    },
    dynamicClass() {
      return ['app-auth-code__list-input', this.isError && 'app-auth-code__list-input_error']
    }
  },
  mounted() {
    for (let i = 0; i < this.countOfInputs; i++) {
      this.codes.push({ value: '' })
    }
  },
}
</script>
