var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "app-radio" }, [
    _c("input", {
      staticClass: "app-radio__input_real",
      attrs: { type: "radio", name: _vm.radioData.groupName },
      domProps: { checked: _vm.isChecked, value: _vm.radioData.value },
      on: {
        change: function($event) {
          return _vm.emitActiveValue($event)
        }
      }
    }),
    _vm._v(" "),
    _c("span", { staticClass: "app-radio__input_custom" }),
    _vm._v("\n  " + _vm._s(_vm.radioData.label) + "\n")
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7edfdd06", { render: render, staticRenderFns: staticRenderFns })
  }
}