// eslint-disable-next-line no-undef
// Эти строки используются для отмены линтинга необъявленной переменной Routing

import Vue from 'vue'
import {
    mapActions,
    mapGetters,
    mapMutations,
    mapState,
} from 'vuex'
import autosize from 'autosize'
import VueAwesomeSwiper from 'vue-awesome-swiper'

import router from './router'
import store from './store'

import * as mutations from './store/types/mutations-types'
import * as actions from './store/types/actions-types'

import validateEmail from './validators/email'
import validatePhone from './validators/phone'
import validateNotEmpty from './validators/notEmpty'

import {
    getOptionsFilter,
    getPriceFilter,
    setFilters,
    setOptionFilter,
    setPriceFilter,
} from './browser/filters'

import localStogareCleaner from './utils/localStogareCleaner'

import imageCropper from './components/imageCropper.vue'
import slidePreview from './components/slidePreview.vue'
import slider from './components/slider.vue'
import AppVue from './AppVue.vue'

import '../../assets/default-new/css/styles.scss'

Vue.use(VueAwesomeSwiper)

localStogareCleaner()

Vue.options.delimiters = ['{!', '!}']

Vue.prototype.isUsingShopLogic = true
Vue.prototype.screenBodyWidth = window.innerWidth

const TOAST_DURATION = 5000

const modalVue = new Vue({
    el: '#appModal',
    store,
    router,

    components: {
        AppVue,
    },

    methods: {
        ...mapMutations({
            openModal: mutations.OPEN_MODAL,
            closeModal: mutations.CLOSE_MODAL,
            resetImagePosition: mutations.RESET_IMAGE_POSITION,
            resetActiveToggle: mutations.RESET_ACTIVE_TOGGLE,
            resetActivePriceVariant: mutations.RESET_ACTIVE_PRICE_VARIANT,
            resetSelectedModifiers: mutations.RESET_SELECTED_MODIFIERS,
            setProduct: mutations.SET_PRODUCT,
            selectGroupModifier: mutations.SELECT_GROUP_MODIFIER,
            setDraft: mutations.SET_DRAFT,
            setAmount: mutations.SET_AMOUNT,
            setImagePath: mutations.SET_IMAGE_PATH,
            setMainImage: mutations.SET_MAIN_IMAGE,
            resetActiveImage: mutations.RESET_ACTIVE_IMAGE,
        }),

        showModalProduct(slug, path) {
            this.setImagePath(path)
            this.resetActiveImage()

            // eslint-disable-next-line no-undef
            const url = Routing.generate('product', { slug, _format: 'json' })

            fetch(url)
                .then(response => response.json())
                .then(product => {
                    this.setMainImage(product.images[0])
                    this.setProduct(product)
                    // Нужно улучшить код с условиями.
                    // Он нужен, чтобы не было ошибок, если элементов variants не существует
                    this.setAmount((product.variants[0] === undefined)
                        ? 0 : product.variants[0].amount)
                    this.selectGroupModifier((product.variants[0] === undefined)
                        ? {} : product.variants[0].groupModifiers[0])
                    this.setDraft({
                        productId: product.id,
                        parameters: [(product.variants[0] === undefined ? '' : product.variants[0].parameters[0])],
                    })
                })
                .then(() => this.openModal())
        },

        closeModalProduct() {
            this.closeModal()
            this.resetImagePosition()
            this.resetActiveToggle()
            this.resetActivePriceVariant()
            this.resetSelectedModifiers()
            this.currentSlideIndex = 0
        },
    },

    render(h) {
        return h(AppVue)
    },
})

const vueApp = new Vue({
    el: '#vue-app',
    store,
    router,
    data: () => ({
        errors: {
            email: false,
            name: false,
            phone: false,
            city: false,
            street: false,
            house: false,
            address: true,
            deliveryType: false,
            allowPersonalDataProcessing: false,
        },
        modalVue,
        imgPath: '',
        slideLinkType: 'product',
    }),

    components: {
        imageCropper,
        slidePreview,
        slider,
    },
    computed: {
        ...mapState({
            products: state => state.products,
            lastOrderId: state => state.customer.lastOrderId,
            httpInProgress: state => state.httpInProgress,
            questions: state => state.questions,
            cities: state => state.cities,
            city: state => state.customer.city,
            cityExact: state => state.cityExact,
            optionsVariants: state => state.filters.options,
            minPrice: state => state.filters.price.min,
            maxPrice: state => state.filters.price.max,
            croppedImage: state => state.croppedImage,
            slideLinkOptions: state => state.slideLinkOptions,
            deliveryPrice: state => state.customer.delivery.deliveryPrice,

            deliveryIntervals: state => state.deliveryIntervals,
            deliveryType: state => state.customer.delivery.type,
        }),
        ...mapGetters({
            totalSum: 'totalSum',
            totalQuantity: 'totalQuantity',
            draftQuantity: 'draftQuantity',
            draftSum: 'draftSum',
            discriminatedDraftQuantity: 'discriminatedDraftQuantity',
            discriminatedDraftTotalSum: 'discriminatedDraftTotalSum',
            availableParameters: 'availableParameters',
        }),
        filterPriceFrom: {
            get() { return this.$store.state.filters.active.price.from },
            set(v) { this.$store.commit(mutations.UPDATE_FILTER_FROM_PRICE, v) },
        },
        filterPriceTo: {
            get() { return this.$store.state.filters.active.price.to },
            set(v) { this.$store.commit(mutations.UPDATE_FILTER_TO_PRICE, v) },
        },
        customerName: {
            get() { return this.$store.state.customer.name },
            set(value) { this.$store.commit(mutations.UPDATE_CUSTOMER_NAME, value) },
        },
        customerEmail: {
            get() { return this.$store.state.customer.email },
            set(value) { this.$store.commit(mutations.UPDATE_CUSTOMER_EMAIL, value) },
        },
        customerPhone: {
            get() { return this.$store.state.customer.phone },
            set(value) { this.$store.commit(mutations.UPDATE_CUSTOMER_PHONE, value) },
        },
        customerMessage: {
            get() { return this.$store.state.customer.message },
            set(value) { this.$store.commit(mutations.UPDATE_CUSTOMER_MESSAGE, value) },
        },
        customerAllowSubscribe: {
            get() { return this.$store.state.customer.allowSpam },
            set(value) { this.$store.commit(mutations.UPDATE_CUSTOMER_ALLOW_SPAM, value) },
        },
        customerAllowPersonalDataProcessing: {
            get() { return this.$store.state.customer.allowPersonalDataProcessing },
            set(value) {
                this.$store.commit(mutations.UPDATE_CUSTOMER_ALLOW_PERSONAL_DATA_PROCESSING, value)
            },
        },
        customerPayment: {
            get() { return this.$store.state.customer.onlinePayment ? 'online' : 'offline' },
            set(v) { this.$store.commit(mutations.UPDATE_CUSTOMER_ONLINE_PAYMENT, v === 'online') },
        },
        customerCity: {
            get() {
                return this.$store.state.customer.address.city !== null
                    ? this.$store.state.customer.address.city
                    : this.$store.state.customer.city
            },
            set(v) { this.$store.commit(mutations.UPDATE_CUSTOMER_ADDRESS_CITY, v) },
        },
        customerStreet: {
            get() { return this.$store.state.customer.address.street },
            set(v) { this.$store.commit(mutations.UPDATE_CUSTOMER_ADDRESS_STREET, v) },
        },
        customerHouse: {
            get() { return this.$store.state.customer.address.house },
            set(v) { this.$store.commit(mutations.UPDATE_CUSTOMER_ADDRESS_HOUSE, v) },
        },
        customerApartment: {
            get() { return this.$store.state.customer.address.apartment },
            set(v) { this.$store.commit(mutations.UPDATE_CUSTOMER_ADDRESS_APARTMENT, v) },
        },
        customerFullAddress: {
            get() {
                const { street } = this.$store.state.customer.address
                const { house } = this.$store.state.customer.address
                const { apartment } = this.$store.state.customer.address

                return apartment
                    ? `${street}, ${house}, ${apartment}`
                    : `${street}, ${house}`
            },
            set(v) {
                const [street, house, apartment] = v.split(',').map(s => s.trim())

                this.$store.commit(mutations.UPDATE_CUSTOMER_ADDRESS_STREET, street)
                this.$store.commit(mutations.UPDATE_CUSTOMER_ADDRESS_HOUSE, house)
                this.$store.commit(mutations.UPDATE_CUSTOMER_ADDRESS_APARTMENT, apartment)
            },
        },
        customerDeliveryDate: {
            get() { return this.$store.state.customer.delivery.date },
            set(v) { this.$store.commit(mutations.UPDATE_CUSTOMER_DELIVERY_DATE, v) },
        },
        customerDeliveryIntervalDate: {
            get() {
                return {
                    min: new Date().toLocaleDateString('en-CA'),
                    max: (() => (
                        this.$store.state.deliveryIntervals.reverse()
                            .find(deliveryInterval => deliveryInterval.length)[0].date
                    ))(),
                }
            },
        },
        availableDeliveryIntervals: {
            get() {
                const deliveryIntervalsForSelectedDay = this.$store.state.deliveryIntervals
                    .filter(deliveryIntervals => deliveryIntervals
                        .filter(deliveryInterval => deliveryInterval.date
                            === this.$store.state.customer.delivery.date).length)[0]
                if (deliveryIntervalsForSelectedDay !== undefined) {
                    return deliveryIntervalsForSelectedDay
                }
                return [{ name: 'доставки нет' }]
            },
        },
        customerDeliveryInterval: {
            get() { return this.$store.state.customer.delivery.interval },
            set(v) { this.$store.commit(mutations.UPDATE_CUSTOMER_DELIVERY_INTERVAL, v) },
        },
    },
    methods: {
        ...mapMutations({
            incrementProductQuantity: mutations.INCREMENT_PRODUCT_QUANTITY,
            decrementProductQuantity: mutations.DECREMENT_PRODUCT_QUANTITY,
            removeProduct: mutations.REMOVE_PRODUCT,
            clearProducts: mutations.CLEAR_PRODUCTS,
            setSlideLinkOptions: mutations.SET_SLIDE_LINK_OPTIONS,
        }),
        updateCity(city) {
            store.commit(mutations.UPDATE_CUSTOMER_CITY, { city, exact: true })
        },
        updateCustomerFile(e) {
            const files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return
            }

            store.commit(mutations.UPDATE_CUSTOMER_FILE, files[0])
        },
        updateAnswer(questionId, question, answer) {
            store.commit(
                mutations.UPDATE_CUSTOMER_ANSWER,
                { questionId, question, answer },
            )
        },
        setDeliveryType(type) {
            store.commit(mutations.UPDATE_CUSTOMER_DELIVERY_DATE, null)
            store.commit(mutations.UPDATE_CUSTOMER_DELIVERY_TYPE, type)
            store.commit(mutations.UPDATE_CUSTOMER_DELIVERY_DATE, '')
            store.commit(mutations.UPDATE_CUSTOMER_ADDRESS_STREET, '')
            store.commit(mutations.UPDATE_CUSTOMER_ADDRESS_HOUSE, '')
            store.commit(mutations.UPDATE_CUSTOMER_ADDRESS_APARTMENT, '')
        },
        toggleOptionFilter(key, value) {
            store.commit(mutations.TOGGLE_FILTER_OPTION, { key, value })
        },

        changeDraftParameter(e, id, key) {
            const { value } = e.target
            store.commit(mutations.CHANGE_DRAFT_PRODUCT_PARAMETER, { id, key, value })
        },
        changeDiscriminatedParameter(e, id, key, discriminator) {
            const { value } = e.target
            store.commit(
                mutations.CHANGE_DRAFT_DISCRIMINATED_PARAMETER,
                {
                    id, key, value, discriminator,
                },
            )
        },

        incrementDraftQuantity(id, price) {
            store.dispatch(
                actions.CHANGE_DRAFT_QUANTITY,
                id,
                1,
                price,
            )
        },
        decrementDraftQuantity(id, price) {
            store.dispatch(
                actions.CHANGE_DRAFT_QUANTITY,
                id,
                -1,
                price,
            )
        },
        incrementDiscriminatedQuantity(id, price, discriminator) {
            store.dispatch(
                actions.CHANGE_DISCRIMINATED_QUANTITY,
                id,
                1,
                price,
                discriminator,
            )
        },
        decrementDiscriminatedQuantity(id, price, discriminator) {
            store.dispatch(
                actions.CHANGE_DISCRIMINATED_QUANTITY,
                id,
                -1,
                price,
                discriminator,
            )
        },

        ...mapActions({
            sendFeedback: actions.SEND_FEEDBACK,
        }),
        addProduct(productId) {
            store.commit(mutations.SET_DRAFT, { productId })
            store.dispatch(actions.ADD_PRODUCT)
        },

        createOrder() {
            this.errors.phone = !validatePhone(this.customerPhone)
            this.errors.name = !validateNotEmpty(this.customerName)
            this.errors.deliveryType = !validateNotEmpty(this.deliveryType)
            if (!this.$store.state.customer.allowPersonalDataProcessing) {
                this.errors.allowPersonalDataProcessing = true
                Vue.toasted.show(
                    'Пожалуйста, прочтите и примите условия обработки персональных данных',
                    { duration: TOAST_DURATION },
                )
                return
            }
            if (this.deliveryType === 'pickup') {
                // самовывоз
                if (!this.errors.phone && !this.errors.name) {
                    this.$store.dispatch(actions.CREATE_ORDER)
                } else {
                    Vue.toasted.show(
                        'Пожалуйста, заполните поля, выделенные красным',
                        { duration: TOAST_DURATION },
                    )
                }
                return
            }

            this.errors.email = !validateEmail(this.customerEmail)
            this.errors.city = !validateNotEmpty(this.customerCity)
            this.errors.street = !validateNotEmpty(this.customerStreet)
            this.errors.deliveryIntervals = !validateNotEmpty(this.customerDeliveryDate)
            this.errors.house = !validateNotEmpty(this.customerHouse)
            this.errors.address = this.errors.street || this.errors.house

            const validRequest = !Object.values(this.errors).reduce((a, b) => a || b)

            if (validRequest) {
                this.$store.dispatch(actions.CREATE_ORDER)
            } else if (this.errors.deliveryType) {
                Vue.toasted.show(
                    'Вероятно, вы забыли указать способ доставки',
                    { duration: TOAST_DURATION },
                )
            } else if (this.errors.address) {
                Vue.toasted.show(
                    'Пожалуйста, укажите адрес',
                    { duration: TOAST_DURATION },
                )
            } else {
                Vue.toasted.show(
                    'Пожалуйста, заполните поля, выделенные красным',
                    { duration: TOAST_DURATION },
                )
            }
        },
        loadDeliveryPrice() {
            store.dispatch(actions.LOAD_DELIVERY_PRICE)
        },
        setPriceFilter() {
            const from = Number(this.$store.state.filters.active.price.from)
            const to = this.$store.state.filters.active.price.to
            || 0 || this.$store.state.filters.price.max

            setPriceFilter({ from, to })
        },
        setOptionFilter() {
            const options = this.$store.state.filters.active.options
                .filter(option => option.values.length)

            setOptionFilter(options)
        },
        clearFilters() {
            setFilters({
                from: this.$store.state.filters.price.min,
                to: this.$store.state.filters.price.max,
            }, [])
        },
        setFilters() {
            const { from, to } = this.$store.state.filters.active.price
            const options = this.$store.state.filters.active.options
                .filter(option => option.values.length)

            setFilters({ from, to }, options)
        },
        optionFilterIsActive(key, value) {
            const activeOption = this.$store.state.filters.active.options
                .find(option => option.key === key.toLowerCase())

            return activeOption
                ? !!activeOption.values.find(v => v === value.toLowerCase())
                : false
        },

        isSlideExist() {
            return !!document.getElementById('slide-id')
        },

        isSliderExist() {
            return !!document.getElementById('slider-json')
        },

        isCropperExist() {
            return !!document.getElementById('slide-cropped-image')
        },

        previewSlide() {
            this.$refs.imageCropper.updateCroppedImg()
            const title = document.getElementById('slide-title').value
            const subtitle = document.getElementById('slide-subtitle').value
            const buttonTitle = document.getElementById('slide-button-title').value
            const titleColor = document.getElementById('slide-title-color').value
            const subtitleColor = document.getElementById('slide-subtitle-color').value
            const align = document.getElementById('slide-description-align').value

            const slidePreviewLoc = {
                title,
                subtitle,
                buttonTitle,
                titleColor,
                subtitleColor,
                align,
            }

            store.commit(mutations.SET_SLIDE_PREVIEW, slidePreviewLoc)
        },

        createSlide() {
            let croppedImage
            let croppedImageData
            try {
                croppedImage = this.$refs.imageCropper.cropImage()
                croppedImageData = JSON.stringify(this.$refs.imageCropper.getData())
            } catch (e) {
                Vue.toasted.show('Не удалось обрезать изображение', { duration: 3000, type: 'error' })
                return
            }
            const croppedImageOrigin = this.$store.state.cropped.imageOrigin

            const id = this.isSlideExist() ? document.getElementById('slide-id').dataset.slideId : undefined

            const active = document.getElementById('slide-status').checked
            const title = document.getElementById('slide-title').value
            const subtitle = document.getElementById('slide-subtitle').value
            const buttonTitle = document.getElementById('slide-button-title').value
            const titleColor = document.getElementById('slide-title-color').value
            const subtitleColor = document.getElementById('slide-subtitle-color').value
            const position = document.getElementById('slide-position').value
            let relationType = document.getElementById('slide-button-link-type').value

            let descriptionAlignRight = false
            let descriptionAlignBottom = false
            switch (document.getElementById('slide-description-align').value) {
                case 'top-left':
                    break
                case 'top-right':
                    descriptionAlignRight = true
                    break
                case 'bottom-left':
                    descriptionAlignBottom = true
                    break
                case 'bottom-right':
                    descriptionAlignRight = true
                    descriptionAlignBottom = true
                    break
                default:
                    break
            }
            const style = JSON.stringify({
                descriptionAlignRight,
                descriptionAlignBottom,
            })

            const slideDataJson = {
                id,
                croppedImage,
                croppedImageOrigin,
                croppedImageData,
                active,
                title,
                subtitle,
                buttonTitle,
                titleColor,
                subtitleColor,
                position,
                style,
            }

            if (document.getElementById('slide-button-link-object')) {
                if (buttonTitle) {
                    slideDataJson.entity = { id: document.getElementById('slide-button-link-object').value } // id Article, ...
                } else {
                    relationType = 'custom'
                    slideDataJson.slideLinkUrl = ''
                }
            } else {
                slideDataJson.slideLinkUrl = document.getElementById('slide-button-custom-link').value
            }

            const routeName = id ? 'update_slide' : 'create_slide'
            // eslint-disable-next-line no-undef
            fetch(Routing.generate(routeName, { type: relationType }), {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(slideDataJson),
            }).then(() => {
                // eslint-disable-next-line no-undef
                window.location.href = Routing.generate('slide_list')
            }).catch(error => {
                Vue.toasted.show('Не удалось сохранить слайд', { duration: 3000, type: 'error' })
                console.error(error)
            })
        },

        deleteSlide(id) {
            // eslint-disable-next-line no-undef
            fetch(Routing.generate('delete_slide', { id }), {
            }).then(() => {
                document.getElementById(`slide-of-list${id}`).classList.add('hide')
            }).catch(error => {
                console.error(error)
            })
        },

        updateSlideLinkOptions(id) {
            const type = document.getElementById('slide-button-link-type').value
            this.slideLinkType = type

            if (!this.slideLinkOptions[type]) {
                if (type !== 'custom') {
                    let routeName = ''
                    switch (type) {
                        case 'product':
                            routeName = 'get_products'
                            break
                        case 'article':
                            routeName = 'get_articles'
                            break
                        case 'articleType':
                            routeName = 'get_article_types'
                            break
                        case 'tag':
                            routeName = 'get_tags'
                            break
                        case 'brand':
                            routeName = 'get_brands'
                            break
                        default:
                            throw new Error('updateSlideLinkOptions: Неизвестный класс слайда')
                    }
                    // eslint-disable-next-line no-undef
                    fetch(Routing.generate(routeName, { currentSlideId: id }), {})
                        .then(response => (response.json()))
                        .then(entities => {
                            const slideLinkOptionList = entities.reduce(
                                (acc, item) => { acc[item.id] = item.title; return acc },
                                {},
                            )
                            store.commit(
                                mutations.SET_SLIDE_LINK_OPTIONS,
                                { [type]: slideLinkOptionList },
                            )
                        })
                        .catch(error => (console.error(error)))
                } else {
                    const slideButtonUrl = document.getElementById('slide-cropped-image').dataset.slideUrl
                    if (slideButtonUrl) {
                        store.commit(mutations.SET_SLIDE_LINK_OPTIONS, { custom: slideButtonUrl })
                    }
                }
            }
        },
    },

    beforeMount() {
        if (this.isCropperExist()) {
            const existingCroppedImage = document.getElementById('slide-cropped-image').dataset.slideCroppedImage
            const existingCroppedImageOrigin = document.getElementById('slide-cropped-image').dataset.slideCroppedImageOrigin
            const existingCroppedImageData = document.getElementById('slide-cropped-image').dataset.slideCroppedImageData

            store.commit(mutations.SET_CROPPED_IMAGE, existingCroppedImage)
            store.commit(mutations.SET_CROPPED_IMAGE_ORIGIN, existingCroppedImageOrigin)
            store.commit(mutations.SET_CROPPED_IMAGE_DATA, existingCroppedImageData)
        }

        if (this.isSliderExist()) {
            const { sliderJson } = document.getElementById('slider-json').dataset
            store.commit(mutations.SET_SLIDES, sliderJson)
        }
    },

    mounted() {
        if (this.isCropperExist()) {
            // инициализация select на страничке кроппера
            const { slideClass } = document.getElementById('slide-cropped-image').dataset
            switch (slideClass) {
                case 'Model\\Slider\\ArticleSlide':
                    this.slideLinkType = 'article'
                    document.getElementById('slide-button-link-type-article').setAttribute('selected', 'selected')
                    break
                case 'Model\\Slider\\ArticleTypeSlide':
                    this.slideLinkType = 'articleType'
                    document.getElementById('slide-button-link-type-articletype').setAttribute('selected', 'selected')
                    break
                case 'Model\\Slider\\BrandSlide':
                    this.slideLinkType = 'brand'
                    document.getElementById('slide-button-link-type-brand').setAttribute('selected', 'selected')
                    break
                case 'Model\\Slider\\ProductSlide':
                    this.slideLinkType = 'product'
                    document.getElementById('slide-button-link-type-product').setAttribute('selected', 'selected')
                    break
                case 'Model\\Slider\\TagSlide':
                    this.slideLinkType = 'tag'
                    document.getElementById('slide-button-link-type-tag').setAttribute('selected', 'selected')
                    break
                case 'Model\\Slider\\CustomSlide':
                    this.slideLinkType = 'custom'
                    document.getElementById('slide-button-link-type-custom').setAttribute('selected', 'selected')
                    break
                case '':
                    this.slideLinkType = 'product'
                    document.getElementById('slide-button-link-type-product').setAttribute('selected', 'selected')
                    break
                default:
                    throw new Error('mounted: Неизвестный класс слайда')
            }

            let { slideStyle } = document.getElementById('slide-cropped-image').dataset
            if (slideStyle) {
                slideStyle = JSON.parse(document.getElementById('slide-cropped-image').dataset.slideStyle)
                if (slideStyle.descriptionAlignRight && slideStyle.descriptionAlignBottom) {
                    document.getElementById('slide-description-align-bottom-right').setAttribute('selected', 'selected')
                } else if (slideStyle.descriptionAlignBottom) {
                    document.getElementById('slide-description-align-bottom-left').setAttribute('selected', 'selected')
                } else if (slideStyle.descriptionAlignRight) {
                    document.getElementById('slide-description-align-top-right').setAttribute('selected', 'selected')
                } else {
                    document.getElementById('slide-description-align-top-left').setAttribute('selected', 'selected')
                }
            }
        }

        store.dispatch(actions.CLEAR_PRODUCTS_ON_CATALOG_UPDATE)

        // Page with `ref="productInfo"` element
        const productInfoRef = this.$refs.productInfo
        if (productInfoRef) {
            store.dispatch(
                actions.LOAD_VARIANTS,
                productInfoRef.dataset.vueProductId,
            )

            store.commit(mutations.RESET_PRODUCT_PARAMETERS)
        }

        // Page with `ref="products"` element
        const productsRef = this.$refs.products
        if (productsRef) {
            const priceFilter = getPriceFilter()
            store.commit(
                mutations.UPDATE_FILTER_FROM_PRICE,
                priceFilter ? priceFilter.from : this.$store.state.filters.price.min,
            )
            store.commit(
                mutations.UPDATE_FILTER_TO_PRICE,
                priceFilter ? priceFilter.to : this.$store.state.filters.price.max,
            )

            const optionFilter = getOptionsFilter()
            store.commit(mutations.UPDATE_FILTER_OPTIONS, optionFilter)

            store.dispatch(actions.LOAD_OPTIONS_FILTERS)
            store.dispatch(actions.LOAD_PRICE_RANGE)
        }

        // Page with `ref="cart"` element
        const cartRef = this.$refs.cart
        if (cartRef) {
            store.dispatch(actions.LOAD_QUESTIONS)

            // workaround!
            store.commit(mutations.UPDATE_CUSTOMER_DELIVERY_TYPE, 'courier')
            store.commit(mutations.UPDATE_CUSTOMER_ONLINE_PAYMENT, false)
        }

        this.imgPath = this.$el.getAttribute('data-img-path')
        Vue.prototype.isUsingShopLogic = setTimeout(this.$el.hasAttribute('data-using-shop-logic'), 10)

        if (this.$route.query.q) {
            this.modalVue.showModalProduct(this.$route.query.q, this.imgPath)
        }

        store.dispatch(actions.LOAD_DELIVERY_INTERVALS)
        store.dispatch(actions.LOAD_CITIES)
        store.dispatch(actions.LOAD_SHOP_CONTACTS)

        store.commit(mutations.SET_SCREEN_WIDTH, this.screenBodyWidth)

        autosize(document.querySelectorAll('textarea'))

        if (this.isCropperExist()) {
            this.previewSlide()
            const id = this.isSlideExist() ? document.getElementById('slide-id').dataset.slideId : 0
            this.updateSlideLinkOptions(id)
        }
    },

    watch: {
        $route(to) {
            if (to.query.q) {
                this.modalVue.showModalProduct(to.query.q, this.imgPath)
            } else {
                this.modalVue.closeModalProduct()
            }
        },
    },
})

window.vueApp = vueApp
window.modalVue = modalVue
if (process.env && process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-underscore-dangle
    window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = vueApp.constructor
    Vue.config.devtools = true
}
