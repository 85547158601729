export const pad = (minutes, countSymbols = 2) => (`00000000${minutes}`).slice(-1 * countSymbols)

export const fullTime = (hours, minutes) => `${pad(hours)}:${pad(minutes)}`

export const convertDateTimeToString = date => {
    const dateObject = new Date(date * 1000)
    return `${fullTime(dateObject.getHours(), dateObject.getMinutes())} ${dateObject.toLocaleDateString()}`
}

export const convertDateToString = date => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
}

export const getTwelveYearsAgoDateString = () => {
    const today = new Date()
    const twelveYearsAgo = new Date(today.getFullYear() - 12, today.getMonth(), today.getDate())
    return convertDateToString(twelveYearsAgo)
}

export const getTwoWeekFutureDateString = () => {
    const futureDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 14)
    return convertDateToString(futureDate)
}
