<template>
  <svg class="app-loader">
    <circle class="app-loader__circle" cx="15" cy="15" r="8"></circle>
  </svg>
</template>

<script>
export default {
  name: 'appLoader',
}
</script>
