var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-accordion" }, [
    _c(
      "button",
      {
        staticClass: "app-accordion__btn",
        class: { "is-active": _vm.isOpen },
        on: {
          click: function($event) {
            _vm.isOpen = !_vm.isOpen
          }
        }
      },
      [
        _c("span", { staticClass: "app-accordion__btn-text" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c(
          "svg",
          {
            attrs: {
              viewBox: "0 0 20 20",
              fill: "#333333",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M17.9543 6.61598C17.7199 6.38164 17.402 6.25 17.0706 6.25C16.7391 6.25\n              16.4212 6.38164 16.1868 6.61598L9.99933 12.8035L3.81183 6.61598C3.57608\n              6.38829 3.26032 6.26229 2.93258 6.26514C2.60483 6.26799 2.29132 6.39945\n              2.05956 6.63121C1.8278 6.86297 1.69633 7.17648 1.69349 7.50423C1.69064\n              7.83198 1.81663 8.14773 2.04433 8.38348L9.11558 15.4547C9.34999 15.6891\n              9.66787 15.8207 9.99933 15.8207C10.3308 15.8207 10.6487 15.6891 10.8831\n              15.4547L17.9543 8.38348C18.1887 8.14907 18.3203 7.83119 18.3203 7.49973C18.3203\n              7.16828 18.1887 6.85039 17.9543 6.61598Z",
                fill: "currentColor"
              }
            })
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "accordionContent",
        staticClass: "app-accordion__content",
        class: { hidden: !_vm.isOpen }
      },
      [_vm._t("body")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6c1a3951", { render: render, staticRenderFns: staticRenderFns })
  }
}