import store from '@store'
import * as actions from '@store/types/actions-types'
import * as mutations from '@store/types/mutations-types'

import { mapState } from 'vuex'

// TODO: QS-1208 - временное решение, сделано для переиспользования (этот файл удалится)
export default {
    computed: {
        ...mapState({
            deliveryAddress: state => state.deliveryAddress,
            orderDetailsDeliveryAddress: state => state.orderDetails.delivery.address,
        }),
        fullStreetOrderPage: {
            get() {
                return this.orderDetailsDeliveryAddress.fullStreet
            },
            set(v) {
                this.$store.commit(mutations.UPDATE_DELIVERY_ADDRESS_FULL_STREET, v)
                this.$store.commit(mutations.CLEAR_DELIVERY_DETAILS)
                store.dispatch(actions.LOAD_STREET_DA_DATA)
            },
        },
        fullStreetModalAddress: {
            get() {
                return this.orderDetailsDeliveryAddress.fullStreet
            },
            set(v) {
                this.$store.commit(mutations.UPDATE_DELIVERY_ADDRESS_FULL_STREET, v)
                this.$store.commit(mutations.CLEAR_DELIVERY_DETAILS)
                store.dispatch(actions.LOAD_STREET_DA_DATA)
            },
        },
    },
}
