<template>
  <section :class="['sidebar', {'open': isOpen}]">
    <div class="sidebar__overlay" @click="hideSidebar"></div>
    <section
      ref="sidebarBody"
      :class="['sidebar__inner', {'right': isRightPosition}]"
      @touchstart="startSwipe($event)"
      @touchmove="swipe($event)"
      @touchend="stopSwipe"
    >
      <slot></slot>
    </section>
  </section>
</template>

<script>
import bodyState from '@helpers/bodyState'

export default {
  name: 'AppSidebar',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isRightPosition: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      isSwiping: false,
      touchStartX: 0,
      touchEndX: 0,
    }
  },
  computed: {
    sidebarWidth() {
      return this.$refs.sidebarBody.offsetWidth
    },
    sidebarBody() {
      return this.$refs.sidebarBody
    },
    swipeDistance() {
      return this.isRightPosition ? this.touchEndX - this.touchStartX : this.touchStartX - this.touchEndX
    },
  },
  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        bodyState.blockBody()
        this.showSidebar()
      } else {
        bodyState.unblockBody()
        this.hideSidebar()
      }
    },
  },
  mounted() {
    // необходимая мера, чтобы сайдбар прилегал к границе экрана, а не находился на расстоянии 100vw
    this.hideSidebar()
  },
  methods: {
    hideSidebar() {
      this.isRightPosition
        ? this.sidebarBody.style.transform = `translate3d(${this.sidebarWidth}px, 0, 0)`
        : this.sidebarBody.style.transform = `translate3d(-${this.sidebarWidth}px, 0, 0)`
      this.$emit('close')
    },
    showSidebar() {
      this.sidebarBody.style.transform = 'translateX(0)'
    },
    startSwipe($event) {
      this.touchStartX = $event.touches[0].clientX
    },
    swipe($event) {
      this.touchEndX = $event.touches[0].clientX

      if (this.swipeDistance > 0) {
        this.isRightPosition
          ? this.$refs.sidebarBody.style.transform = `translate3d(${this.swipeDistance}px, 0, 0)`
          : this.$refs.sidebarBody.style.transform = `translate3d(${-this.swipeDistance}px, 0, 0)`
      }
    },
    stopSwipe() {
      const DISTANCE_TO_CLOSE = 100
      this.isSwiping = false

      this.touchEndX !== 0 && this.swipeDistance > DISTANCE_TO_CLOSE
        ? this.hideSidebar()
        : this.showSidebar()
      this.touchStartX = 0
      this.touchEndX = 0
    },
  },
}
</script>
