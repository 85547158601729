<template>
  <div class="app-radio-tape">
    <label
      v-for="(value, index) in radioData"
      :key="value"
      class="app-radio-tape__input"
      :class="{active: checked === value}"
    >
      <input
        v-model="checked"
        class="app-radio-tape__input_real"
        type="radio"
        :name="value"
        :value="value"
        @change="toggleActiveElement($event, index)"
      >
      <span class="app-radio-tape__input_custom">
        {{ value }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'AppRadioTape',
  props: {
    radioData: {
      type: [Array, Set],
      required: true,
    },
    radioGroup: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['change'],
  data() {
    return {
      checked: '',
    }
  },
  mounted() {
    this.checked = [...this.radioData][0]
  },
  methods: {
    toggleActiveElement($event, index) {
      this.checked = $event.target.value
      this.$emit('change', { checked: this.checked, index })
    },
  },
}
</script>
