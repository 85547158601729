var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-select" }, [
    _c("span", { staticClass: "app-select__label" }, [
      _vm._v(_vm._s(_vm.label))
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "app-select__button",
        class: { "app-select__button_active": _vm.isOpen, error: _vm.isError },
        attrs: { disabled: _vm.isDisabled },
        on: {
          click: function($event) {
            _vm.isOpen = !_vm.isOpen
          }
        }
      },
      [
        _c("span", { staticClass: "app-select__button-text" }, [
          _vm._v(_vm._s(_vm.selectedName))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "app-select__button-icon" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "20",
                height: "20",
                viewBox: "0 0 20 20",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M17.9543 6.61598C17.7199 6.38164 17.402 6.25 17.0706 6.25C16.7391 6.25\n            16.4212 6.38164 16.1868 6.61598L9.99933 12.8035L3.81183 6.61598C3.57608\n            6.38829 3.26032 6.26229 2.93258 6.26514C2.60483 6.26799 2.29132 6.39945\n            2.05956 6.63121C1.8278 6.86297 1.69633 7.17648 1.69349 7.50423C1.69064\n            7.83198 1.81663 8.14773 2.04433 8.38348L9.11558 15.4547C9.34999 15.6891\n            9.66787 15.8207 9.99933 15.8207C10.3308 15.8207 10.6487 15.6891 10.8831\n            15.4547L17.9543 8.38348C18.1887 8.14907 18.3203 7.83119 18.3203 7.49973C18.3203\n            7.16828 18.1887 6.85039 17.9543 6.61598Z",
                  fill: "#333333"
                }
              })
            ]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "app-select__list",
        class: { "app-select__list_visible": _vm.isOpen }
      },
      _vm._l(_vm.options, function(option, i) {
        return _c(
          "li",
          {
            key: i,
            staticClass: "app-select__list-item",
            on: {
              click: function($event) {
                return _vm.onClick(option)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(option.name) + "\n    ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.isError
      ? _c("span", { staticClass: "app-select__text-error" }, [
          _vm._v("\n    Обязательное поле\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-00c53e42", { render: render, staticRenderFns: staticRenderFns })
  }
}