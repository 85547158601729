import { get, getById, post } from './requests'

export const fetchOptionsFilters = () => get('filters/options')
export const fetchPriceRange = () => get('filters/priceRange')
export const fetchQuestions = () => get('questions')
export const fetchCities = () => get('cities')
export const fetchShopContacts = () => get('shop-contacts')
export const fetchDeliveryIntervals = () => get('deliveryIntervals')

export const fetchVariants = productId => getById('variants', productId)
export const fetchParameters = productId => getById('parameters', productId)

export const calculateVariants = data => post('variants/calculate', data)
export const createOrder = data => post('orders/create', data)
export const fetchDeliveryPrice = data => post('deliveryPrice', data)

export const getDefaultCity = () => get('defaultCity')
export const getLastUpdate = () => get('last-update')
