<template>
    <div class="slide-preview__content">
        <div>
            <swiper-slide class="swiper-slide" v-if="croppedImage">
                <div class="swiper-slide__block">
                    <div class="swiper-slide__description"
                        :class="{
                            'swiper-slide__description-align-right': descriptionAlignRight,
                            'swiper-slide__description-align-bottom': descriptionAlignBottom,
                        }">
                        <h3 :style="'color:' + titleColor">{{ title }}</h3>
                        <p :style="'color:' + subtitleColor">{{ subtitle }}</p>
                        <a class="swiper-slide__button" v-if="buttonTitle">{{ buttonTitle }}</a>
                    </div>
                    <div class="swiper-slide__img">
                        <img :src="croppedImage">
                    </div>
                </div>
            </swiper-slide>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '../store'
import * as mutations from '../store/types/mutations-types'

import RotateRightIcon from 'vue-material-design-icons/RotateRight.vue'
import RotateLeftIcon from 'vue-material-design-icons/RotateLeft.vue'
import FlipHorizontalIcon from 'vue-material-design-icons/FlipHorizontal.vue'
import FlipVerticalIcon from 'vue-material-design-icons/FlipVertical.vue'
import ReloadIcon from 'vue-material-design-icons/Reload.vue'
import FileUploadOutlineIcon from 'vue-material-design-icons/FileUploadOutline.vue'

export default {
    name: 'slide-preview',
    store,
    components: {
        RotateRightIcon,
        RotateLeftIcon,
        FlipHorizontalIcon,
        FlipVerticalIcon,
        ReloadIcon,
        FileUploadOutlineIcon,
    },
    computed: {
        ...mapState({
            title: state => state.slidePreview.title,
            subtitle: state => state.slidePreview.subtitle,
            buttonTitle: state => state.slidePreview.buttonTitle,
            titleColor: state => state.slidePreview.titleColor,
            subtitleColor: state => state.slidePreview.subtitleColor,
            descriptionAlignRight: state => state.slidePreview.align === 'top-right' || state.slidePreview.align === 'bottom-right',
            descriptionAlignBottom: state => state.slidePreview.align === 'bottom-left' || state.slidePreview.align === 'bottom-right',
        }),
        croppedImage: {
            get() { return this.$store.state.cropped.image },
            set(value) { this.$store.commit(mutations.UPDATE_CROPPED_IMAGE, value) },
        },
    },
};
</script>

<style>
@import '../../../assets/css/variables.css';

.swiper-slide__block {
    position: relative;
    width: 100%;
    height: 500px;
}

.swiper-slide__description-align-right {
    align-items: flex-end !important;
    left: 68%;
}

.swiper-slide__description-align-right p, .swiper-slide__description-align-right h3 {
    text-align: right !important;
}

.swiper-slide__description-align-bottom {
    bottom: 0;
}

.swiper-slide__description {
    position: absolute;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    width: 25%;
    max-height: 85%;
    flex-direction: column;
    padding: 30px 50px;
    z-index: 10;
}

.swiper-slide__description h3 {
    flex-shrink: 0;
    margin: 0;
    max-width: 150%;
    word-wrap: break-word;
    overflow: hidden;
    font-size: 38px;
    font-weight: normal;
}

.swiper-slide__description p {
    max-width: 100%;
    word-wrap: break-word;
    overflow: hidden;
    font-size: 18px;
    line-height: 28px;
}

.swiper-slide__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    width: 197px;
    height: 45px;
    text-decoration: none;
    color: #F2F2F2;
    background: #EF4618;
}

.swiper-slide__img {
    right: 0;
}

.swiper-slide__img img {
    width: 100%;
    border-radius: var(--border-radius-small);
}

.swiper-slide__button:hover {
    cursor: pointer;
    background-color: color-mod(var(--main-color) blackness(+10%));/*color: #84b65d(blackness(+10%)) */
}

@media (max-width: 1440px) {
    .swiper-slide {
        height: 333px;
    }
}

@media (max-width: 1200px) {
    .swiper-slide {
        height: 274px;
    }
}

@media (max-width: 990px) {
    .swiper-slide {
        height: 210px;
    }

    .swiper-slide__description {
        padding: 10px 30px;
    }

    .swiper-slide__description h3 {
        font-size: 30px;
    }

    .swiper-slide__description p {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .swiper-slide {
        height: 155px;
    }

    .swiper-slide__description p {
        line-height: 1.5em;
    }
}

@media (max-width: 600px) {
    .swiper-slide {
        height: 148px;
    }

    .swiper-slide__description h3 {
        font-size: 24px;
    }

    .swiper-slide__description p {
        font-size: 12px;
        margin: 0;
    }

    .swiper-slide__button {
        padding: 6px 8px;
        min-width: auto;
        line-height: 10px;
        font-size: 12px;
    }
}

@media (max-width: 414px) {
    .swiper-slide {
        height: 100px;
    }

    .swiper-slide__description h3 {
        font-size: 18px;
    }

    .swiper-slide__description p {
        font-size: 10px;
    }

    .swiper-slide__button {
        padding: 4px 6px;
        min-width: auto;
        line-height: 10px;
        font-size: 10px;
    }
}
</style>
