<template>
  <div
    class="app-product-slider"
    @mousedown="startSwipe($event, 'mouse')"
    @touchstart="startSwipe($event, 'finger')"
    @mousemove="swipe($event, 'mouse')"
    @touchmove="swipe($event, 'finger')"
    @mouseup="stopSwipe"
    @touchend="stopSwipe"
  >
    <div v-show="isLoading" class="app-product-slider__skeleton skeleton-animation"></div>
    <div 
      v-show="!isLoading && images.length > 1 && currentIndex" 
      class="app-product-slider__slide-left__button"
      @click="changeSlide(currentIndex - 1)"
    >
      <svg class="app-product-slider__slide-left__button__arrow" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.9543 6.61598C17.7199 6.38164 17.402 6.25 17.0706 6.25C16.7391 6.25
                16.4212 6.38164 16.1868 6.61598L9.99933 12.8035L3.81183 6.61598C3.57608
                6.38829 3.26032 6.26229 2.93258 6.26514C2.60483 6.26799 2.29132 6.39945
                2.05956 6.63121C1.8278 6.86297 1.69633 7.17648 1.69349 7.50423C1.69064
                7.83198 1.81663 8.14773 2.04433 8.38348L9.11558 15.4547C9.34999 15.6891
                9.66787 15.8207 9.99933 15.8207C10.3308 15.8207 10.6487 15.6891 10.8831
                15.4547L17.9543 8.38348C18.1887 8.14907 18.3203 7.83119 18.3203 7.49973C18.3203
                7.16828 18.1887 6.85039 17.9543 6.61598Z"
            class="app-product-slider__slide-left__button__arrow-img"
        />
      </svg>
    </div>
    <img
      v-show="!isLoading"
      class="app-product-slider__image"
      :src="getPath()"
      :alt="`product ${currentIndex}`"
      draggable="false"
      @load="onLoad"
    >
    <div v-show="isImageLoading" class="app-product-slider__image__loader-container">
      <app-spinner />
    </div>
    <div
      v-show="!isLoading && images.length > 1 && currentIndex !== images.length - 1" 
      class="app-product-slider__slide-right__button"
      @click="changeSlide(currentIndex + 1)"
    >
      <svg class="app-product-slider__slide-right__button__arrow" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.9543 6.61598C17.7199 6.38164 17.402 6.25 17.0706 6.25C16.7391 6.25
                16.4212 6.38164 16.1868 6.61598L9.99933 12.8035L3.81183 6.61598C3.57608
                6.38829 3.26032 6.26229 2.93258 6.26514C2.60483 6.26799 2.29132 6.39945
                2.05956 6.63121C1.8278 6.86297 1.69633 7.17648 1.69349 7.50423C1.69064
                7.83198 1.81663 8.14773 2.04433 8.38348L9.11558 15.4547C9.34999 15.6891
                9.66787 15.8207 9.99933 15.8207C10.3308 15.8207 10.6487 15.6891 10.8831
                15.4547L17.9543 8.38348C18.1887 8.14907 18.3203 7.83119 18.3203 7.49973C18.3203
                7.16828 18.1887 6.85039 17.9543 6.61598Z"
            class="app-product-slider__slide-left__button__arrow-img"
        />
      </svg>
    </div>
    <div v-if="images.length > 1" class="app-product-slider__pagination">
      <button
        v-for="(_, index) in images"
        :key="index"
        :class="paginationButtonClass(index)"
        @click="changeSlide(index)"
      >
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import appSpinner from '../app-spinner/appSpinner.vue';

export default {
  name: 'AppProductSlider',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    needResetComponent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    appSpinner,
  },
  data() {
    return {
      currentIndex: 0,
      startX: 0,
      isSwiping: false,
      isLoading: true,
      isImageLoading: false,
    }
  },
  computed: {
    ...mapState({
      routeToImageFolder: state => state.pathImg,
    }),
    currentImageHash() {
      return this.images[this.currentIndex]
    },
  },
  watch: {
    needResetComponent() {
      this.currentIndex = 0
      this.isLoading = true
      this.isImageLoading = false
    },
  },
  methods: {
    getPath() {
      return this.routeToImageFolder + this.currentImageHash
    },
    onLoad() {
      this.isLoading = false
      this.isImageLoading = false
    },
    paginationButtonClass(index) {
      return ['app-product-slider__pagination-button', { active: this.currentIndex === index }]
    },
    changeSlide(index) {
      this.isImageLoading = true
      this.currentIndex = index
    },
    startSwipe($event, touchType) {
      $event.preventDefault()
      touchType === 'mouse' ? this.startX = $event.clientX : this.startX = $event.touches[0].clientX
      this.isSwiping = true
    },
    swipe($event, touchType) {
      if (this.isSwiping) {
        let distance
        touchType === 'mouse'
          ? distance = $event.clientX - this.startX
          : distance = $event.touches[0].clientX - this.startX

        if (distance > 100 && this.currentIndex !== 0) {
          this.changeSlide(this.currentIndex - 1)
          this.stopSwipe()
        } else if (distance < -100 && this.currentIndex < this.images.length - 1) {
          this.changeSlide(this.currentIndex + 1)
          this.stopSwipe()
        }
      }
    },
    stopSwipe() {
      this.isSwiping = false
    },
  },
}
</script>
