var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-button",
    {
      staticClass: "app-progress-button",
      attrs: { "is-disabled": _vm.isDisabled || _vm.inProgress },
      on: { click: _vm.click }
    },
    [
      _vm.inProgress
        ? _c(
            "span",
            { staticClass: "app-progress-button__inner" },
            [
              _c("app-loader"),
              _vm._v("\n    " + _vm._s(_vm.progressText) + "\n  ")
            ],
            1
          )
        : _c("span", [_vm._v(_vm._s(_vm.buttonText))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-668aa3db", { render: render, staticRenderFns: staticRenderFns })
  }
}