var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["app-textarea", _vm.dynamicWrapClasses] }, [
    _c("span", { class: ["app-textarea__label", { active: _vm.isActive }] }, [
      _vm._v(_vm._s(_vm.label))
    ]),
    _vm._v(" "),
    _c("textarea", {
      attrs: { disabled: _vm.isDisabled, required: _vm.isRequired },
      domProps: { value: _vm.value },
      on: {
        focus: function($event) {
          _vm.isActive = true
        },
        blur: _vm.onBlur,
        input: function($event) {
          return _vm.$emit("input", $event.target.value)
        }
      }
    }),
    _vm._v(" "),
    _vm.isError
      ? _c("p", { staticClass: "app-textarea__error" }, [
          _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-11dd3784", { render: render, staticRenderFns: staticRenderFns })
  }
}