var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-counter" }, [
    _c("button", {
      staticClass: "app-counter__button app-counter__button_negative",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.decrement.apply(null, arguments)
        }
      }
    }),
    _vm._v(" "),
    _c("span", { staticClass: "app-counter__count" }, [
      _vm._v(_vm._s(_vm.count))
    ]),
    _vm._v(" "),
    _c("button", {
      staticClass: "app-counter__button app-counter__button_positive",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.increment.apply(null, arguments)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0846fcb4", { render: render, staticRenderFns: staticRenderFns })
  }
}