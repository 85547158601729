import yml from '@config/parameters.yml'

export const DA_DATA_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'

const createOptionsForDaData = (state, token) => ({
    method: 'POST',
    mode: 'cors',
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Accept: 'application/json',
        Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
        query: `${yml.parameters['app.city_da_data']} ${state.deliveryAddress.address.fullStreet}`,
        to_bound: { value: 'house' },
        locations_boost: [
            {
                country: yml.parameters['app.country_da_data'],
                city_with_type: `г ${yml.parameters['app.city_da_data']}`,
                city: `${yml.parameters['app.city_da_data']}`,
            },
        ],
        locations: [
            { city_fias_id: 'e3b0eae8-a4ce-4779-ae04-5c0797de66be' },
            { city_fias_id: '53c22352-eb6f-4163-a4d1-385d64561d2f' },
            { settlement_fias_id: 'c947ed73-acc9-4935-9dad-cc2e0dfc1f75' },
        ],
    }),
})

export default (state, token) => createOptionsForDaData(state, token)
